import { createSlice } from '@reduxjs/toolkit';
import { reset } from 'redux-form';
import { adminAPI } from '../../api/adminAPI';
import { errorHandle } from './errorHandler';
import { setMessage } from './messageSlice';
import { setServices } from './taskSlice';

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        isLoading: false,
        companies: [],
        users: [],
        user: {},
        company: {},
        specialist: {}
    },
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setCompanies: (state, action) => {
            state.companies = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;            
        },
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        setSpecialist: (state, action) => {
            state.specialist = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        
    }
});

export const {setUsers, setCompanies, setUser, setCompany, setSpecialist, setLoading } = adminSlice.actions;

export const getUsers = () => dispatch => {
    dispatch(setLoading(true));
    adminAPI.getUsers()
    .then((response) => {
        if (response.status === 200) {
            dispatch(setUsers(response.data.users));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        dispatch(setUsers([]));
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    });
}

export const getSpecialistUsers = () => dispatch => {
    dispatch(setLoading(true));
    adminAPI.getSpecialistUsers()
    .then((response) => {
        if (response.status === 200) {
            dispatch(setUsers(response.data.users));
            dispatch(setServices(response.data.services));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        dispatch(setUsers([]));
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    });
}

export const getCompanies = () => dispatch => {
    dispatch(setLoading(true));
    adminAPI.getCompanies()
    .then((response) => {
        if (response.status === 200) {
            dispatch(setCompanies(response.data.companies));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        dispatch(setCompanies([]));
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    });
}

export const getCompany = (id) => dispatch => {
    dispatch(setLoading(true));
    adminAPI.getCompany(id)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setCompany(response.data.company));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        dispatch(setCompany({}));
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    });
}

export const updateCompany = (id, data) => dispatch => {
    dispatch(setLoading(true));
    adminAPI.updateCompany(id, data)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setCompany(response.data.company));
            dispatch(setMessage({message: ['Компания успешно сохранена'], type: 'success'}));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    });
}

export const createCompany = (data) => dispatch => {
    dispatch(setLoading(true));
    adminAPI.createCompany(data)
    .then((response) => {
        if (response.status === 201) {
            dispatch(reset('company_create'));
            dispatch(setMessage({message: ['Компания успешно создана'], type: 'success'}));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    });
}

export const getUser = (id) => dispatch => {
    dispatch(setLoading(true));
    adminAPI.getUser(id)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setUser(response.data.user));
            dispatch(setCompanies(response.data.companies));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        dispatch(setUser({}));
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    });
}

export const updateUser = (id, data) => dispatch => {
    dispatch(setLoading(true));
    adminAPI.updateUser(id, data)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setMessage({message: ['Данные пользователя успешно обновлены'], type: 'success'}));
            dispatch(setUser(response.data.user));
            dispatch(setCompanies(response.data.companies));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    });
}

export const createUser = (data) => dispatch => {
    dispatch(setLoading(true));
    adminAPI.createUser(data)
    .then((response) => {
        if (response.status === 201) {
            dispatch(setMessage({message: ['Пользователь успешно создан'], type: 'success'}));
            dispatch(reset('user_create'));            
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    });
}

export const getSpecialist = (id) => dispatch => {
    dispatch(setLoading(true));
    adminAPI.getSpecialist(id)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setSpecialist(response.data.specialist));
            dispatch(setServices(response.data.services));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        dispatch(setSpecialist({}));
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    });
}

export const createSpecialist = (data) => dispatch => {
    dispatch(setLoading(true));
    adminAPI.createSpecialist(data)
    .then((response) => {
        if (response.status === 201) {
            dispatch(setUsers(response.data.users));
            dispatch(setServices(response.data.services));
            dispatch(setMessage({message: ['Специалист успешно создан'], type: 'success'}));
            dispatch(reset('specialist_create'));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    });
}

export const selectLoading = state => state.admin.isLoading;
export const selectUsers = state => state.admin.users;
export const selectCompanies = state => state.admin.companies;
export const selectCompany = state => state.admin.company;
export const selectUser = state => state.admin.user;
export const selectSpecialist = state => state.admin.specialist;

export default adminSlice.reducer;

