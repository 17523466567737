import React, { useEffect, useState } from 'react';

import { Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';

const { Column } = Table;

const ItCalcTaskDetailTable = ({task}) => {

    const [costSum, setCostSum] = useState(0);

    useEffect(() => {
        if (!!task.services) {
            let sum = 0;
            task.services.forEach(service => sum += service.amount * service.unit_price * (1 - service.discount * 0.01));
            setCostSum(sum);
        }
        
    }, [task]);

    return (
        <>
            <Table
                dataSource={task.services}
                pagination={false}
                size='small'
                scroll={{x: true}}
                title={() => <Title level={5} style={{fontWeight: 700}}>Услуги</Title>}
            >
                <Column 
                    title="Услуга"
                    key="label"
                    dataIndex={['service', 'label']}
                />
                <Column
                    title="Количество" 
                    dataIndex="amount" 
                    key="amount"
                    render={(amount, record) => <>{amount} {record.service.value_type_display}</>}
                />
                <Column
                    title="Скидка"
                    dataIndex="discount"
                    key="discount"
                    render={discount => `${discount}%`}
                />
                <Column
                    title="Стоимость за единицу"
                    dataIndex="unit_price"
                    key="unit_price"
                    render={(unit_price, record) => `${parseInt(unit_price) * (1 - record.discount * 0.01)} тг`}
                />                
                <Column
                    title="Стоимость" 
                    dataIndex="unit_price" 
                    key="unit_price"
                    render={(unit_price, record) => <>{record.amount * parseInt(unit_price) * (1 - record.discount * 0.01)} тг</>}
                />
            </Table>
            <Title level={5} style={{textAlign: 'right', marginTop: '10px'}}>Общая стоимость - {costSum} тг</Title>
        </>
    )
}

export default ItCalcTaskDetailTable;