import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import YandexMapModal from '../YandexMapFrame/YandexMapModal';

const CompanyAddress = () => {

    const [isModalVisible, setModalVisible] = useState(false);

    const onModalClose = () => {
        setModalVisible(false);
    }

    const onModalOpen = () => {
        setModalVisible(true);
    }

    return (
        <>
            <div className="company_address">
                <Link type='link' onClick={onModalOpen}>
                    г. Алматы, 1-й мкр, 50/2, БЦ Болашак
                </Link><br/>
                Пн—Пт 09:00—18:00
            </div>
            <YandexMapModal isVisible={isModalVisible} onClose={onModalClose}/>
        </>
    )
}

export default CompanyAddress;