import React from 'react';
import { useDispatch } from 'react-redux';


import { Button, Form } from 'antd';
import { reduxForm } from 'redux-form';
import { createField, Input, PhoneInput, SmallTextArea } from '../general/FormControls/FormsControls';
import { EmailValidation, phoneMinLength, required } from '../../utils/validators/validators';
import { createFastTask } from '../../store/reducers/taskSlice';

const form = 'fast_task_create';

const FastTaskForm = ({handleSubmit, isLoading}) => {
    
    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(createFastTask({...formData}));
    }

    return (
        <Form layout='vertical'>
            {createField('ФИО', "client_name", [required], Input, {})}
            {createField('8 (XXX) XXX-XX-XX', 'phone_number', [required, phoneMinLength], PhoneInput, {})}
            {createField('Email', 'email', [required, EmailValidation], Input, {})}
            {createField('Опишите вашу проблему кратко', "commentary", [required], SmallTextArea, {})}
            <Form.Item>
                <Button type="primary" loading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    className="login_form__button">
                        Создать заявку
                </Button>
            </Form.Item>
        </Form>
    )
}

export const FastTaskReduxForm = reduxForm({form: form})(FastTaskForm);