import React from 'react';
import { Link } from 'react-router-dom';

import { Badge, Table } from 'antd';
import Time from 'react-time-format';
import { ParsePhoneWhatsApp } from '../../../../../components/general/FormControls/FormsControls';

const { Column } = Table;

const userTypeFilters = [
    {text: 'Физическое лицо', value: 1},
    {text: 'Юридическое лицо', value: 2}
]

const AdminTaskTable = ({tasks}) => {

    return (
        <Table 
            dataSource={tasks} 
            pagination={{position: ['topRight'], defaultCurrent: 1, defaultPageSize: 10, total: tasks.length}} 
            size='small' 
            scroll={{x: true}} 
            locale={{filterReset: 'Очистить', filterConfirm: 'Применить', emptyText: 'Заявки отсутствуют'}}
        >
            <Column
                title="" 
                dataIndex="admin_notifications" 
                key="admin_notifications" 
                render={admin_notifications => <Badge count={admin_notifications} />}
            />
            <Column 
                title="№" 
                dataIndex="id" 
                key="id"
                render={id => <Link to={`/cabinet/admin-tasks/${id}`}>Заявка №{id}</Link>}
            />
            <Column 
                title="Email" 
                dataIndex={['client', 'email']} 
                key="email"
            />
            <Column 
                title="Номер телефона" 
                dataIndex={['client', 'phone_number']} 
                key="phone_number"
                render={phone_number => <ParsePhoneWhatsApp phone={phone_number}/>}                                    
            />
            <Column 
                title="Тип лица" 
                dataIndex={['client', 'type']} 
                key="type"
                render={type => <>{type === 2 ? <>Юридическое лицо</> : <>Физическое лицо</>}</>}
                filters={userTypeFilters}
                onFilter={(value, record) => record.client?.type === value}
            />
            <Column 
                title="Статус" 
                dataIndex={['status', 'name']}
                key="status"
            />
            <Column 
                title="Дата создания" 
                dataIndex="created_time" 
                key="created_time" 
                render={created_time => <Time value={created_time} format='DD.MM.YYYY hh:mm'/>}
            />
            <Column
                title="Дата изменения" 
                dataIndex="updated_time" 
                key="updated_time" 
                render={updated_time => <Time value={updated_time} format='DD.MM.YYYY hh:mm'/>}
            />
        </Table>
    )
}

export default AdminTaskTable;