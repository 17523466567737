import React from 'react';

import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { LeftOutlined } from '@ant-design/icons';

import { ServiceTaskCreateReduxForm } from './ServiceTaskCreateReduxForm';
import { selectLoading } from '../../../../store/reducers/itServiceSlice';
import Title from 'antd/lib/typography/Title';
import { Link } from 'react-router-dom';

const ServiceTaskCreatePage = () => {

    const history = useHistory();

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    const isLoading = useSelector(selectLoading);

    return (
        <Row gutter={16}>
            <Col xs={24} md={22} lg={18} xl={14} xxl={10}>
                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Создание заявки на Возврат/Ремонт</Title>
                <ServiceTaskCreateReduxForm 
                    isLoading={isLoading}
                />
            </Col>
        </Row>
    )
}

export default ServiceTaskCreatePage;