import { LeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { getRubrics, selectLoading, selectRubrics } from '../../../../store/reducers/articleSlice';
import { ArticleCreateReduxForm } from './ArticleCreateReduxForm';

const ArticleCreatePage = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const rubrics = useSelector(selectRubrics);
    const isLoading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getRubrics());
    }, []);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col span={24}>
                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Создание статьи</Title>
                <ArticleCreateReduxForm 
                    isLoading={isLoading}                              
                    rubrics={rubrics}
                />
            </Col>
        </Row>
    )
}

export default ArticleCreatePage;