import React from 'react';

import { useSelector } from 'react-redux';
import { selectUserRole } from '../../../store/reducers/authSlice';
import { Col, Row } from 'antd';
import MenuItem from '../../../components/general/MenuItem';
import Title from 'antd/lib/typography/Title';
import { admin_items, data_items, manager_items, user_items } from './CabinetMenuItems';

const CabinetPage = () => {
    const user_role = useSelector(selectUserRole);

    return (
        <>
            <Row gutter={16}>
                {['client', 'specialist'].includes(user_role) &&
                    <>  
                        {user_items.map(item => {
                            return (
                                <MenuItem item={item}/>
                            )
                        })}                        
                    </>
                }
                {['manager', 'admin'].includes(user_role) &&
                    <>
                        {manager_items.map(item => {
                            return (
                                <MenuItem item={item}/>
                            )
                        })} 
                    </>
                }
            </Row>
            <Row gutter={16} style={{marginTop: '20px'}}>
                {user_role === 'admin' && 
                    <>
                        <Col span={24}>
                            <Title level={4} style={{textAlign: 'center'}}>
                                Админ-панель
                            </Title>
                        </Col>
                        {admin_items.map(item => {
                            return (
                                <MenuItem item={item}/>
                            )
                        })}
                    </>
                   
                }
            </Row>
            <Row gutter={16} style={{marginTop: '20px'}}>                
                <Col span={24}>
                    <Title level={4} style={{textAlign: 'center'}}>
                        Данные
                    </Title>
                </Col>
                {data_items.map(item => {
                    return (
                        <MenuItem item={item}/>
                    )
                })}
            </Row>
        </>
        
    )
}

export default CabinetPage;