import React, { useState } from 'react';

import { Button, Table } from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ItCalcServiceDiscountDeleteModal from './ItCalcServiceDiscountDeleteModal';
const { Column } = Table;

const ItCalcServiceDiscountTable = ({discounts}) => {
    const [visible, setVisible] = useState(false);
    const [discountId, setDiscountId] = useState(0);

    const handleDiscountDelete = (id) => {
        setDiscountId(id);
        setVisible(true);
    }

    return (
        <>
            <Table 
                dataSource={discounts}
                pagination={false} 
                size='small'
                scroll={{x: true}} 
                locale={{filterReset: 'Очистить', filterConfirm: 'Применить', emptyText: 'Скидки отсутствуют'}}
            >
                <Column
                    title="Скидка" 
                    dataIndex="discount" 
                    key="discount"
                    render={discount => `${discount}%`}
                />
                <Column
                    title="Минимальное количество" 
                    dataIndex="amount" 
                    key="amount"
                />
                <Column
                    title="" 
                    dataIndex="id" 
                    key="id"
                    render={id => {
                        return (
                            <Button type='primary'>
                                <Link to={`/cabinet/it-calc-service-discounts/${id}`}><EditOutlined /></Link>
                            </Button>
                        )
                    }}
                />
                <Column
                    title="" 
                    dataIndex="id" 
                    key="id"
                    render={id => {
                        return (
                            <Button type='primary' danger onClick={e => handleDiscountDelete(id)}>
                                <DeleteOutlined />
                            </Button>
                        )
                    }}
                />
            </Table>
            {!!discountId &&
                <ItCalcServiceDiscountDeleteModal visible={visible} setVisible={setVisible} discountId={discountId}/>
            }
            
        </>
    )
}

export default ItCalcServiceDiscountTable;