import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import { Form, Button, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { createField, Input } from '../../components/general/FormControls/FormsControls.jsx';
import { required } from '../../utils/validators/validators';
import { login, selectLoading } from '../../store/reducers/authSlice';


const form = 'login';

const LoginForm = ({handleSubmit, isLoading}) => {

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(login(formData));
    }

    return (
        <Form layout='vertical'>
            {createField('Email', "username", [required], Input, {prefix: <UserOutlined />})}
            {createField('Пароль', "password", [required], Input, {prefix: <LockOutlined />, type: 'password'})}

            <Form.Item>
                <Button type="primary" loading={isLoading}
                    className="login_form__button"
                    onClick={handleSubmit(onSubmit)}>
                        Войти
                </Button>
            </Form.Item>
        </Form>
    )
}

const UserLoginReduxForm = reduxForm({form: form})(LoginForm);

const LoginPage = () => {
    const isLoading = useSelector(selectLoading);

    return (
        <Row>
            <Col xs={{span: 24}} lg={{span: 12, offset: 6}}  xxl={{span: 6, offset: 9}}>                
                <UserLoginReduxForm
                    isLoading={isLoading}
                />
                <div style={{textAlign: 'center'}}>
                    Нет аккаунта? <Link to='/registration'>Зарегистрироваться</Link>
                </div>
                <div style={{textAlign: 'center'}}>
                    Забыли пароль? <Link to='/password-forget'>Восстановить</Link>
                </div>
            </Col>
        </Row>
    )
}

export default LoginPage;