import React from 'react';

import { Descriptions, Select } from 'antd';
import { ParsePhoneWhatsApp } from '../../../../components/general/FormControls/FormsControls';
import { useDispatch } from 'react-redux';
import { updateTaskStatus } from '../../../../store/reducers/itServiceSlice';

const { Option } = Select;

const ServiceTaskInfoTable = ({task}) => {
    const dispatch = useDispatch();

    const handleStatusChange = (task_id, status) => {
        dispatch(updateTaskStatus(task_id, status));
    }

    if (!!task) {
        return (
            <>
                <Descriptions title="Информация о клиенте" bordered column={1} size='small' style={{marginBottom: '20px'}}>
                    <Descriptions.Item label="ФИО">{task.client_name}</Descriptions.Item>
                    <Descriptions.Item label="Email">{task.email}</Descriptions.Item>
                    <Descriptions.Item label="Номер телефона"><ParsePhoneWhatsApp phone={task.phone_number}/></Descriptions.Item>
                </Descriptions>
                <Descriptions title="Информация о заявке" bordered column={1} size='small' style={{marginBottom: '20px'}}>
                    <Descriptions.Item label="Тип">{task.type}</Descriptions.Item>
                    <Descriptions.Item label="Название продукта">{task.product_name}</Descriptions.Item>
                    <Descriptions.Item label="Артикул продукта">{task.product_sku}</Descriptions.Item>
                    <Descriptions.Item label="Статус">
                        <Select 
                            defaultValue={!!task.status && task.status.name} 
                            style={{width: '300px'}} 
                            onChange={(value) => handleStatusChange(task.id, value)}
                            notFoundContent=''
                        >
                            {!!task.status && 
                                <>
                                    {task.status.children.map(status => <Option value={status.id}>{status.name}</Option>)}
                                </>
                            }                            
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label="Комментарий">{task.commentary}</Descriptions.Item>
                </Descriptions>
            </>
        )
    }
    else return null;    
}

export default ServiceTaskInfoTable;