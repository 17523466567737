import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoggedIn } from "../store/reducers/authSlice";

const UnPrivateRoute  = (props) => {
  const {component: Component, ...rest} = props;
  const isLoggedIn = useSelector(selectLoggedIn);

  return (
    <Route
      {...rest}
      render={props => !isLoggedIn
          ? <Component {...props} />
          : <Redirect to="/" />
      }
    />
  )
}

export default withRouter(UnPrivateRoute);