import React, { useEffect } from 'react';

import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getArticleDetail, selectArticle, selectLoading } from '../../../../store/reducers/articleSlice';
import Title from 'antd/lib/typography/Title';
import DataSkeleton from '../../../../components/general/DataSkeleton';


const ArticleDetailPage = (props) => {

    const dispatch = useDispatch();

    const id = props.match.params.articleId;
    
    const article = useSelector(selectArticle);
    const isLoading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getArticleDetail(id));
    }, [id]);


    return (
        <Row gutter={16}>
            <Col xs={{span: 24}} lg={{span: 16, offset: 4}} xxl={{span: 12, offset: 6}}>
                {isLoading ?
                    <DataSkeleton/>
                    :
                    <>
                        <Title level={2}>{article.name}</Title>
                        <div 
                            className='article_container'
                            dangerouslySetInnerHTML={{__html: article.text}}
                        />
                    </>
                }
                
            </Col>
        </Row>
    )
}

export default ArticleDetailPage;