import { Col, List } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectArticles } from '../../store/reducers/articleSlice';

const FooterSpecialists = () => {
    const [specialistArticles, setSpecialistArticles] = useState([]);

    const articles = useSelector(selectArticles);

    useEffect(() => {
        setSpecialistArticles(articles.filter(article => article.rubric?.name === 'Специалисты'))
    }, [articles]);

    return (
        <Col xs={24} md={5} className='footer_block'>
            <Title level={5}>Наши специалисты</Title>
            <List>
                {specialistArticles.map(article => <li><Link to={`/articles/${article.id}`}>{article.name}</Link></li>)}
            </List>
            
        </Col>
    )
}

export default FooterSpecialists;