import { Button, Col, Form, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { reduxForm } from 'redux-form';
import { createField, Input, Label } from '../../components/general/FormControls/FormsControls';
import { confirmRegistration, selectLoading } from '../../store/reducers/authSlice';
import { EmailValidation, required } from '../../utils/validators/validators';

const form = 'registration_confirmation';

const ConfirmationForm = ({handleSubmit, isLoading}) => {

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(confirmRegistration(formData));
    }

    return (        
        <Form layout='vertical'>
            {createField('Email', 'email', [required, EmailValidation], Input, {disabled: true, label: Label('Email')})}
            {createField('Код подтверждения', 'token', [required], Input, {label: Label('Код подтверждения')})}
            <Form.Item style={{textAlign: 'center'}}>
                <Button type="primary" loading={isLoading} onClick={handleSubmit(onSubmit)}>
                    Подтвердить
                </Button>
            </Form.Item>
        </Form>
    )
}

const ConfirmationReduxForm = reduxForm({form: form})(ConfirmationForm);

const ConfirmationPage = () => {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const email = params.get('email');

    const isLoading = useSelector(selectLoading);

    return (
        <Row gutter={16}>
            <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}} xxl={{span: 8, offset: 8}}>
                <ConfirmationReduxForm isLoading={isLoading} initialValues={{email}}/>
            </Col>
        </Row>
    )
}

export default ConfirmationPage;