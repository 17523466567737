import React, { useState } from 'react';

import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useDispatch } from 'react-redux';
import { createField, Label, SpecialistCreateServicesSelect, SpecialistUserSelect } from '../../../../components/general/FormControls/FormsControls';
import { reduxForm } from 'redux-form';
import { createSpecialist } from '../../../../store/reducers/adminSlice';
import { required, requiredArray } from '../../../../utils/validators/validators';
import { Link } from 'react-router-dom';

const form = 'specialist_create';

const SpecialistCreateForm = ({handleSubmit, isLoading, users, services}) => {

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(createSpecialist(formData));
    }

    return (
        <Form layout='vertical'>            
            {createField('Выберите пользователя', 'user', [required], SpecialistUserSelect, {users, loading: isLoading, label: Label('Пользователь')})}
            <Link to='/cabinet/create-user'><PlusOutlined /> Создать пользователя</Link>
            {createField('Выберите одну или несколько услуг', 'services', [requiredArray], SpecialistCreateServicesSelect, {services, loading: isLoading, label: Label('Услуги')})}
            <Form.Item>
                <Button type="primary" loading={isLoading}
                    onClick={handleSubmit(onSubmit)}>
                        Сохранить
                </Button>
            </Form.Item>
        </Form>
    )
}

export const SpecialistCreateReduxForm = reduxForm({form: form})(SpecialistCreateForm);