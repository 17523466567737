import React, { useEffect } from 'react';

import { Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';

import { Link, useHistory } from 'react-router-dom';
import { CompanyCreateReduxForm, UserCreateReduxForm } from './CompanyCreateReduxForm';
import { getCompanies, selectCompanies, selectLoading } from '../../../../store/reducers/adminSlice';

const { Title } = Typography;

const CompanyCreatePage = () => {
    
    const history = useHistory();
    
    const isLoading = useSelector(selectLoading);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col xs={24} md={22} lg={18} xl={14} xxl={10}>
                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Создание компании</Title>
                <CompanyCreateReduxForm 
                    isLoading={isLoading}                    
                />
            </Col>
        </Row>
    )
}

export default CompanyCreatePage;