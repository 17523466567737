import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { Row, Col } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';

import { getServices, selectLoading, selectServices } from '../../../../store/reducers/taskSlice.js';
import { TaskCreateReduxForm } from './TaskCreateReduxForm';


const TaskCreatePage = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getServices());
    }, []);

    const isLoading = useSelector(selectLoading);
    const services = useSelector(selectServices);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row>
            <Col xs={24} md={22} lg={18} xl={14} xxl={10}>
                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Создание заявки</Title>
                <TaskCreateReduxForm
                    isLoading={isLoading}
                    services={services}
                />
            </Col>
        </Row>
    )
}

export default TaskCreatePage;