import { configureStore } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';

import authReducer from './reducers/authSlice';
import taskReducer from './reducers/taskSlice';
import itCalcReducer from './reducers/itCalcSlice';
import messageReducer from './reducers/messageSlice';
import itServiceReducer from './reducers/itServiceSlice';
import adminReducer from './reducers/adminSlice';
import outsorcingReducer from './reducers/outsorcingSlice';
import articleReducer from './reducers/articleSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    task: taskReducer,
    itCalc: itCalcReducer,
    message: messageReducer,
    form: formReducer,
    itService: itServiceReducer,
    admin: adminReducer,
    outsorcing: outsorcingReducer,
    article: articleReducer
  },
  devTools: false,
});