import React from 'react';

import { Popover } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const ServiceLabel = ({popover, content}) => {
    if (!!popover) {
        return (
            <div>
                <Popover 
                    placement='right' 
                    content={popover}
                >
                    <CheckOutlined style={{color: '#175fa4'}}/> {content}
                </Popover>
            </div>
        )
    }
    else {
        return (
            <div>
                <CheckOutlined style={{color: '#175fa4'}}/> {content}                
            </div>
        )
    }
}

export default ServiceLabel;