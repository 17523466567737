import { createSlice } from '@reduxjs/toolkit';
import { outsorcingAPI } from '../../api/outsorcingAPI';
import { taskAPI } from '../../api/taskAPI';
import { errorHandle } from './errorHandler';
import { setMessage } from './messageSlice';
import { reset } from 'redux-form';

export const outsorcingSlice = createSlice({
    name: 'outsorcing',
    initialState: {
        contracts: [],
        contract: {},
        tasks: [],
        historyTasks: [],
        task: {},
        services: [],
        service: {},
        specialists: [],
        isLoading: false,
    },
    reducers: {
        setContracts: (state, action) => {
            state.contracts = action.payload;
        },
        setContract: (state, action) => {
            state.contract = action.payload;
        },
        setTasks: (state, action) => {
            state.tasks = action.payload;
        },
        setHistoryTasks: (state, action) => {
            state.historyTasks = action.payload;
        },
        setTask: (state, action) => {
            state.task = action.payload;
        },
        setServices: (state, action) => {
            state.services = action.payload;
        },
        setService: (state, action) => {
            state.service = action.payload;
        },
        setServiceImage: (state, action) => {
            state.service.image = action.payload;
        },
        setSpecialists: (state, action) => {
            state.specialists = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        removeTasks: state => {
            state.tasks = [];
            state.task = {};
        }
    }
});

export const { setContracts, setContract, setTasks, setHistoryTasks, setTask, setServices, setService, setLoading, removeTasks, setSpecialists } = outsorcingSlice.actions;

export const getContracts = () => dispatch => {
    dispatch(setLoading(true));
    outsorcingAPI.getContracts()
    .then((response) => {
        if (response.status === 200) {
            dispatch(setContracts(response.data.contracts));            
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setContracts([]));
        dispatch(setLoading(false));
    })
}

export const createTask = (data) => dispatch => {
    dispatch(setLoading(true));
    taskAPI.createTask(data)
    .then((response) => {
        if (response.status === 201) {            
            dispatch(setMessage({message: ['Заявка успешно создана'], type: 'success'}));
            dispatch(reset('task_create'));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {        
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const getMyTasks = () => dispatch => {
    dispatch(setLoading(true));
    taskAPI.getMyTasks()
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTasks(response.data.tasks));
            dispatch(setHistoryTasks(response.data.history_tasks));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setTasks([]));
        dispatch(setLoading(false));
    })
}

export const getAdminTasks = () => dispatch => {
    dispatch(setLoading(true));
    taskAPI.getAdminTasks()
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTasks(response.data.tasks));
            dispatch(setHistoryTasks(response.data.history_tasks));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setTasks([]));
        dispatch(setLoading(false));
    })
}

export const getTaskDetail = id => dispatch => {
    dispatch(setLoading(true));
    taskAPI.getTaskDetail(id)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTask(response.data.task));
            dispatch(readTaskProgress(id));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setTask({}));
        dispatch(setLoading(false));
    })
}

export const getAdminTaskDetail = id => dispatch => {
    dispatch(setLoading(true));
    taskAPI.getAdminTaskDetail(id)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTask(response.data.task));
            dispatch(readTaskProgress(id));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setTask({}));
        dispatch(setLoading(false));
    })
}

export const readTaskProgress = id => dispatch => {
    taskAPI.readTaskProgress(id)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const getServices = () => dispatch => {
    dispatch(setLoading(true));
    taskAPI.getServices()
    .then((response) => {
        if (response.status === 200) {
            dispatch(setServices(response.data.services));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const getService = (service_id) => dispatch => {
    dispatch(setLoading(true));
    taskAPI.getService(service_id)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setService(response.data.service));
            dispatch(setSpecialists(response.data.specialists));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const getSpecialists = () => dispatch => {
    dispatch(setLoading(true));
    taskAPI.getSpecialists()
    .then((response) => {
        if (response.status === 200) {            
            dispatch(setSpecialists(response.data.specialists));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const createService = (data) => dispatch => {
    dispatch(setLoading(true));
    taskAPI.createService(data)
    .then((response) => {
        if (response.status === 200) {            
            dispatch(setMessage({message: ['Услуга успешно создана'], type: 'success'}));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const updateService = (service_id, data) => dispatch => {
    dispatch(setLoading(true));
    taskAPI.updateService(service_id, data)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setService(response.data.service));
            dispatch(setSpecialists(response.data.specialists));
            dispatch(setMessage({message: ['Услуга успешно сохранена'], type: 'success'}));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const changeTaskSpecialist = (service_id, specialist_id) => dispatch => {
    dispatch(setLoading(true));
    taskAPI.changeServiceSpecialist(service_id, specialist_id)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTask(response.data.task));
            dispatch(setLoading(false));
            dispatch(setMessage({message: ['Специалист изменен'], type: 'success'}));
        }
    })
    .catch((error) => {
        dispatch(setLoading(false));
        errorHandle(error, dispatch);
    })
}

export const changeTaskStatus = (taskId, status) => dispatch => {
    dispatch(setLoading(true));
    taskAPI.changeTaskStatus(taskId, status)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTask(response.data.task));
            dispatch(setLoading(false));
            dispatch(setMessage({message: ['Статус заявки изменен'], type: 'success'}));
        }
    })
    .catch((error) => {
        dispatch(setLoading(false));
        errorHandle(error, dispatch);
    })
}

export const createTaskMessage = (task_id, message) => dispatch => {
    dispatch(setLoading(true));
    taskAPI.createTaskMessage(task_id, message)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTask(response.data.task));
            dispatch(setLoading(false));
            dispatch(setMessage({message: ['Сообщение отправлено'], type: 'success'}));
        }
    })
    .catch((error) => {
        dispatch(setLoading(false));
        errorHandle(error, dispatch);
    })
}

export const selectLoading = state => state.outsorcing.isLoading;
export const selectServices = state => state.outsorcing.services;
export const selectService = state => state.outsorcing.service;
export const selectSpecialists = state => state.outsorcing.specialists;
export const selectTasks = state => state.outsorcing.tasks;
export const selectHistoryTasks = state => state.outsorcing.historyTasks;
export const selectContracts = state => state.outsorcing.contracts;
export const selectTask = state => state.outsorcing.task;

export default outsorcingSlice.reducer;