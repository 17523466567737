import React from 'react';

import { Button, Form } from 'antd';

import { useDispatch } from 'react-redux';
import { createField, Input, NumberInput, SmallTextArea, Label, SpecialistSelectCreate } from '../../../../components/general/FormControls/FormsControls';
import { required, requiredArray } from '../../../../utils/validators/validators';
import { reduxForm } from 'redux-form';
import { createService } from '../../../../store/reducers/taskSlice';

const form = 'service_create';

const ServiceCreateForm = ({handleSubmit, isLoading, specialists}) => {

    const dispatch = useDispatch();

    const onSubmit = (formData) => {        
        dispatch(createService(formData));
    }

    return (
        <Form layout='vertical'>
            {createField('Название', 'name', [required], Input, {label: Label('Название')})}
            {createField('Описание', 'description', [required], SmallTextArea, {label: Label('Описание')})}
            {createField('', 'price', [required], NumberInput, {label: Label('Стоимость (тг)')})}
            {createField('', 'specialists', [requiredArray], SpecialistSelectCreate, {specialists: specialists, label: Label('Специалисты')})}
            <Form.Item>
                <Button 
                    type="primary" 
                    loading={isLoading}
                    style={{marginTop: '20px'}}
                    onClick={handleSubmit(onSubmit)}>
                        Сохранить
                </Button>
            </Form.Item>
        </Form>
    )
}

export const ServiceCreateReduxForm = reduxForm({form: form})(ServiceCreateForm);