import React from 'react';
import { Link } from 'react-router-dom';

const LogoShop = () => {
    return (
        <div className='logo__container'>
            <a href='https://rs6.kz' target='_blank' rel='noreferrer'>
                <img src='/media/logo_shop.png' alt='' className='logo__icon'/>
            </a>
        </div>
    )
}

export default LogoShop;