import React, { useEffect } from 'react';

import { Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';

import { Link, useHistory } from 'react-router-dom';
import { getSpecialistUsers, selectLoading, selectUsers } from '../../../../store/reducers/adminSlice';
import { selectServices } from '../../../../store/reducers/taskSlice';
import { SpecialistCreateReduxForm } from './SpecialistCreateReduxForm';

const { Title } = Typography;

const SpecialistCreatePage = () => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const services = useSelector(selectServices);
    const users = useSelector(selectUsers);
    const isLoading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getSpecialistUsers());
    }, []);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col xs={24} md={22} lg={18} xl={14} xxl={10}>
                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Создание специалиста</Title>
                <SpecialistCreateReduxForm
                    users={users}
                    services={services}
                    isLoading={isLoading}
                />
            </Col>
        </Row>
    )
}

export default SpecialistCreatePage;