import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { PlusOutlined } from '@ant-design/icons';

import DataSkeleton from '../../../../components/general/DataSkeleton';
import SpecialistTable from './SpecialistTable';
import { getSpecialists, selectSpecialists, selectLoading } from '../../../../store/reducers/taskSlice';

const SpecialistListPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSpecialists());
    }, []);

    const isLoading = useSelector(selectLoading);
    const specialists = useSelector(selectSpecialists);

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    : 
                    <>
                        <Title level={2} style={{textAlign: 'center'}}>Специалисты</Title>
                        <SpecialistTable specialists={specialists}/>
                        <Button type='primary' style={{marginTop: '10px'}}>
                            <Link to='/cabinet/create-specialist'><PlusOutlined /> Создать специалиста</Link>
                        </Button>
                    </>
                    
                }
            </Col>
        </Row>
    )
}

export default SpecialistListPage;