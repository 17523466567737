import React from 'react';

import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { ParsePhoneWhatsApp } from '../../../../components/general/FormControls/FormsControls';

const { Column } = Table;

const SpecialistTable = ({specialists}) => {

    return (
        <Table 
            dataSource={specialists} 
            pagination={{position: ['topRight'], defaultCurrent: 1, defaultPageSize: 10, total: specialists.length}} 
            size='small' 
            scroll={{x: true}} 
            locale={{filterReset: 'Очистить', filterConfirm: 'Применить', emptyText: 'Специалисты отсутствуют'}}
        >
            <Column
                title="id" 
                dataIndex="id"
                key="id"                
            />
            <Column
                title="Специалист" 
                dataIndex={['user', 'last_name']} 
                key="last_name"
                render={(last_name, record) => <Link to={`/cabinet/specialists/${record.id}`}>{last_name} {record.user.first_name} {record.user.patronymic}</Link>}  
            />
            <Column
                title="Номер телефона" 
                dataIndex={['user', 'phone_number']}
                key="phone_number"
                render={phone_number => <ParsePhoneWhatsApp phone={phone_number}/>}
            />
            <Column
                title="Пользователь"
                dataIndex={['user', 'email']}
                key="email"
                render={(email, record) => <Link to={`/cabinet/users/${record.user?.id}`}>{email}</Link>}
            />
        </Table>
    )
}

export default SpecialistTable;