import { LeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import { getArticleEdit, selectArticle, selectLoading, selectRubrics } from '../../../../store/reducers/articleSlice';
import { ArticleEditReduxForm } from './ArticleEditReduxForm';


const ArticleEditPage = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const rubrics = useSelector(selectRubrics);
    const article = useSelector(selectArticle);
    const isLoading = useSelector(selectLoading);

    const id = props.match.params.articleId;

    useEffect(() => {
        dispatch(getArticleEdit(id));
    }, []);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    :
                    <>
                        <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Статья №{article.id}</Title>
                        <ArticleEditReduxForm 
                            isLoading={isLoading}                              
                            rubrics={rubrics}
                            article={article}
                            initialValues={{...article}}
                        />
                    </>
                }
            </Col>
        </Row>
    )
}

export default ArticleEditPage;