import React, { useEffect } from 'react';

import { Col, Row } from 'antd';
import { Typography } from 'antd';

import { getServices, selectLoading, selectServices } from '../../../../store/reducers/itCalcSlice';
import { useDispatch, useSelector } from 'react-redux';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import ItCalcServiceTable from './ItCalcServiceTable';

const { Title } = Typography;

const ItCalcServiceListPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getServices());
    }, []);

    const services = useSelector(selectServices);
    const isLoading = useSelector(selectLoading);

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    : 
                    <>
                        <Title level={2} style={{textAlign: 'center'}}>Услуги IT Калькулятора</Title>
                        <ItCalcServiceTable services={services}/>
                    </>
                }
            </Col>
        </Row>
    )
}

export default ItCalcServiceListPage;