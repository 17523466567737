import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { PlusOutlined } from '@ant-design/icons';

import DataSkeleton from '../../../../components/general/DataSkeleton';
import { getCompanies, selectCompanies, selectLoading } from '../../../../store/reducers/adminSlice';
import CompanyTable from './CompanyTable';

const CompanyListPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCompanies());
    }, []);

    const isLoading = useSelector(selectLoading);
    const companies = useSelector(selectCompanies);

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    : 
                    <>
                        <Title level={2} style={{textAlign: 'center'}}>Компании</Title>
                        <CompanyTable companies={companies}/>
                        <Button type='primary' style={{marginTop: '10px'}}>
                            <Link to='/cabinet/create-company'><PlusOutlined /> Создать компанию</Link>
                        </Button>
                    </>
                    
                }
            </Col>
        </Row>
    )
}

export default CompanyListPage;