import { instance } from "./api";

export const adminAPI = {
    getUsers() {
      return instance.get('auth/users/');
    },
    createUser(data) {
      return instance.post(`auth/users/`, data);
    },
    getCompanies() {
      return instance.get('auth/companies/');
    },
    createCompany(data) {
      return instance.post('auth/companies/', data);
    },
    getCompany(id) {
      return instance.get(`auth/companies/${id}/`);
    },
    updateCompany(id, data) {
      return instance.post(`auth/companies/${id}/`, data);
    },
    getUser(id) {
      return instance.get(`auth/users/${id}/`);
    },
    updateUser(id, data) {
      return instance.post(`auth/users/${id}/`, data);
    },
    getSpecialist(id) {
      return instance.get(`task/specialists/${id}/`);
    },
    createSpecialist(data) {
      return instance.post('task/specialists/', data);
    },
    updateSpecialist(id, data) {
      return instance.post(`task/specialists/${id}/`, data);
    },
    getSpecialistUsers() {
      return instance.get('auth/specialist-users/');
    }
}