import React, { useEffect } from 'react';

import { Button, Col, Row } from 'antd';
import { Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons'

import { getMyTasks, selectHistoryTasks, selectLoading, selectTasks } from '../../../../../store/reducers/taskSlice';
import { useDispatch, useSelector } from 'react-redux';
import ClientTaskTable from './ClientTaskTable';
import DataSkeleton from '../../../../../components/general/DataSkeleton';
import { Link } from 'react-router-dom';
import { selectUserRole } from '../../../../../store/reducers/authSlice';

const { Title } = Typography;

const ClientTaskListPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMyTasks());
    }, []);

    const tasks = useSelector(selectTasks);
    const historyTasks = useSelector(selectHistoryTasks);
    const isClient = useSelector(selectUserRole) === 'client';
    const userRole = useSelector(selectUserRole);
    const isLoading = useSelector(selectLoading);

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    : 
                    <>
                        <Title level={2} style={{textAlign: 'center'}}>Мои заявки</Title>
                        <ClientTaskTable tasks={tasks} userRole={userRole}/>
                        {isClient &&
                            <Button type='primary' style={{marginTop: '10px'}}>
                                <Link to='/cabinet/create-task'><PlusOutlined /> Создать заявку</Link>
                            </Button>
                        }
                        <div style={{marginTop: '50px'}}>
                            <Title level={2} style={{textAlign: 'center'}}>История заявок</Title>
                            <ClientTaskTable tasks={historyTasks} userRole={userRole}/>
                        </div>
                        
                    </>
                    
                }
            </Col>
        </Row>  
    )
}

export default ClientTaskListPage;