import { instance } from "./api";

export const taskAPI = {
    createTask(data) {
      return instance.post('task/tasks/', data);
    },
    createFastTask(data) {
      return instance.post('task/fast-tasks/', data);
    },
    getServices() {
      return instance.get('task/services/');
    },
    getService(serviceId) {
      return instance.get(`task/services/${serviceId}/`);
    },
    createService(data) {
      return instance.post('task/services/', data);
    },
    updateService(serviceId, data) {
      return instance.post(`task/services/${serviceId}/`, data);
    },
    getSpecialists() {
      return instance.get('task/specialists/');
    },  
    getMyTasks() {
      return instance.get('task/tasks/');
    },
    getTaskDetail(taskId) {
      return instance.get(`task/tasks/${taskId}/`);
    },
    getAdminTasks() {
      return instance.get('task/admin-tasks/');
    },
    getAdminTaskDetail(taskId) {
      return instance.get(`task/admin-tasks/${taskId}/`);
    },
    getFastTasks() {
      return instance.get('task/fast-tasks/');
    },
    getFastTaskDetail(taskId) {
      return instance.get(`task/fast-tasks/${taskId}/`);
    },
    changeServiceSpecialist(service_id, specialist_id) {
      return instance.post('task/change-service-specialist/', {service_id, specialist_id});
    },
    changeTaskStatus(task_id, status) {
      return instance.post('task/change-task-status/', {task_id, status});
    },
    changeFastTaskStatus(taskId, status) {
      return instance.put(`task/fast-tasks/status/${taskId}/`, {status});
    },
    changeFastTaskSpecialist(taskId, specialist_id) {
      return instance.put(`task/fast-tasks/specialist/${taskId}/`, {specialist_id});
    },
    changeTaskStatusFromList(task_id, status) {
      return instance.post('task/change-task-status-from-list/', {task_id, status});
    },
    createTaskMessage(task_id, commentary) {
      return instance.post('task/create-task-message/', {task_id, commentary});
    },
    readTaskProgress(task_id) {
      return instance.post('task/read-task-progress/', {task_id});
    }
}