import { createSlice } from '@reduxjs/toolkit';
import { errorHandle } from './errorHandler';
import { setMessage } from './messageSlice';
import { reset } from 'redux-form';
import { articleAPI } from '../../api/articleAPI';
import history from '../../routes/history';

export const articleSlice = createSlice({
    name: 'article',
    initialState: {
        articles: [],
        article: {},
        rubrics: [],
        rubric: {},
        isLoading: false,
    },
    reducers: {
        setArticles: (state, action) => {
            state.articles = action.payload;
        },
        setArticle: (state, action) => {
            state.article = action.payload;
        },
        setRubrics: (state, action) => {
            state.rubrics = action.payload;
        },
        setRubric: (state, action) => {
            state.rubric = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    }
});

export const { setArticles, setArticle, setRubrics, setRubric, setLoading } = articleSlice.actions;

export const getRubrics = () => dispatch => {
    dispatch(setLoading(true));
    articleAPI.getRubrics()
    .then(response => {
        if (response.status === 200) {
            dispatch(setRubrics(response.data.rubrics));
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        dispatch(setRubrics([]));
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const getArticles = () => dispatch => {
    dispatch(setLoading(true));
    articleAPI.getArticles()
    .then(response => {
        if (response.status === 200) {
            dispatch(setArticles(response.data.articles));
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        dispatch(setArticles([]));
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const getArticleDetail = (id) => dispatch => {
    dispatch(setLoading(true));
    articleAPI.getArticleDetail(id)
    .then(response => {
        if (response.status === 200) {
            dispatch(setArticle(response.data.article));
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        dispatch(setArticle({}));
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const getArticleEdit = (id) => dispatch => {
    dispatch(setLoading(true));
    articleAPI.getArticleEdit(id)
    .then(response => {
        if (response.status === 200) {
            dispatch(setArticle(response.data.article));
            dispatch(setRubrics(response.data.rubrics));
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        dispatch(setArticle({}));
        dispatch(setRubrics([]));
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const createArticle = (data) => dispatch => {
    dispatch(setLoading(true));
    articleAPI.createArticle(data)
    .then(response => {
        if (response.status === 201) {
            dispatch(setMessage({message: ['Статья успешно создана'], type: 'success'}));
            history.push('/cabinet/articles');
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const updateArticle = (articleId, data) => dispatch => {
    dispatch(setLoading(true));
    articleAPI.updateArticle(articleId, data)
    .then(response => {
        if (response.status === 200) {
            dispatch(setMessage({message: ['Статья успешно обновлена'], type: 'success'}));
            dispatch(setArticle(response.data.article));
            dispatch(setRubrics(response.data.rubrics));
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const deleteArticle = (articleId) => dispatch => {
    dispatch(setLoading(true));
    articleAPI.deleteArticle(articleId)
    .then(response => {
        if (response.status === 200) {
            dispatch(setMessage({message: ['Статья успешно удалена'], type: 'success'}));
            dispatch(setArticles(response.data.articles));
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const selectRubrics = state => state.article.rubrics;
export const selectRubric = state => state.article.rubric;
export const selectArticles = state => state.article.articles;
export const selectArticle = state => state.article.article;
export const selectLoading = state => state.article.isLoading;


export default articleSlice.reducer;