import React, { useState } from 'react';

import { Button, Form } from 'antd';

import { useDispatch } from 'react-redux';
import { createField, Input, Label, RichTextEditor, RubricsSelect } from '../../../../components/general/FormControls/FormsControls';
import { required } from '../../../../utils/validators/validators';
import { reduxForm } from 'redux-form';
import { createArticle } from '../../../../store/reducers/articleSlice';
import { setMessage } from '../../../../store/reducers/messageSlice';

const form = 'article_create';

const ArticleCreateForm = ({handleSubmit, isLoading, rubrics}) => {

    const [text, setText] = useState('');

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        if (!!text) {
            dispatch(createArticle({...formData, text}));
        }
        else {
            dispatch(setMessage({message: ['Поле текста не должно быть пустым'], type: 'error'}))
        }
    }

    return (
        <Form layout='vertical'>
            {createField('Название', 'name', [required], Input, {label: Label('Название')})}
            {createField('', 'rubric', [required], RubricsSelect, {rubrics: rubrics, label: Label('Рубрика')})}
            <RichTextEditor label={Label('Текст')} value={text} setValue={setText}/>
            <Form.Item>
                <Button 
                    type="primary" 
                    loading={isLoading}
                    style={{marginTop: '20px'}}
                    onClick={handleSubmit(onSubmit)}>
                        Сохранить
                </Button>
            </Form.Item>
        </Form>
    )
}

export const ArticleCreateReduxForm = reduxForm({form: form})(ArticleCreateForm);