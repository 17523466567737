import { Button, Modal } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteServiceDiscount } from '../../../../store/reducers/itCalcSlice';

const ItCalcServiceDiscountDeleteModal = ({visible, setVisible, discountId}) => {
    const dispatch = useDispatch();

    const handleCancel = () => {
        setVisible(false);
    }

    const handleSubmit = () => {
        dispatch(deleteServiceDiscount(discountId));
        setVisible(false);
    }

    return (
        <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={[
            <Button type='primary' onClick={handleCancel}>
                Отмена
            </Button>,
            <Button type='primary' danger onClick={handleSubmit}>
                Удалить
            </Button>
        ]}
        >
            Вы действительно хотите удалить скидку?
        </Modal>
    )
}

export default ItCalcServiceDiscountDeleteModal;