import { createSlice } from '@reduxjs/toolkit';
import { itServiceAPI } from '../../api/itServiceAPI';
import { errorHandle } from './errorHandler';
import { setMessage } from './messageSlice';
import { reset } from 'redux-form';

export const itServiceSlice = createSlice({
    name: 'itService',
    initialState: {
        tasks: [],
        historyTasks: [],
        task: {},
        statuses: [],
        endedStatuses: [],
        isLoading: false,
    },
    reducers: {
        setTasks: (state, action) => {
            state.tasks = action.payload;
        },
        setHistoryTasks: (state, action) => {
            state.historyTasks = action.payload;
        },
        setTask: (state, action) => {
            state.task = action.payload;
        },
        setStatuses: (state, action) => {
            state.statuses = action.payload;
        },
        setEndedStatuses: (state, action) => {
            state.endedStatuses = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    }
});

export const { setTasks, setHistoryTasks, setTask, setStatuses, setEndedStatuses, setLoading } = itServiceSlice.actions;

export const createTask = (data) => dispatch => {
    dispatch(setLoading(true));
    itServiceAPI.createTask(data)
    .then((response) => {
        if (response.status === 201) {            
            dispatch(setMessage({message: ['Заявка успешно создана'], type: 'success'}));
            dispatch(reset('service_task_create'));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {        
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const getTasks = () => dispatch => {
    dispatch(setLoading(true));
    itServiceAPI.getTasks()
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTasks(response.data.tasks));
            dispatch(setHistoryTasks(response.data.history_tasks));
            dispatch(setStatuses(response.data.statuses));
            dispatch(setEndedStatuses(response.data.ended_statuses));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {        
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const getTaskDetail = id => dispatch => {
    dispatch(setLoading(true));
    itServiceAPI.getTaskDetail(id)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTask(response.data.task));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setTask({}));
        dispatch(setLoading(false));
    })
}

export const updateTaskStatus = (task_id, status) => dispatch => {
    dispatch(setLoading(true));
    itServiceAPI.updateTaskStatus(task_id, status)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTask(response.data.task));            
            dispatch(setMessage({message: ['Статус заявки изменен'], type: 'success'}));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        dispatch(setLoading(false));
        errorHandle(error, dispatch);
    })
}

export const getTasksByPhoneOrEmail = data => dispatch => {
    dispatch(setLoading(true));
    itServiceAPI.getTasksByPhoneOrEmail(data)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTasks(response.data.tasks));
            dispatch(reset('service_page'));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(reset('service_page'));        
        dispatch(setTasks([]));
        dispatch(setLoading(false));
    })
}

export const selectLoading = state => state.itService.isLoading;
export const selectTasks = state => state.itService.tasks;
export const selectHistoryTasks = state => state.itService.historyTasks;
export const selectTask = state => state.itService.task;
export const selectCurrentStatuses = state => state.itService.statuses;
export const selectEndedStatuses = state => state.itService.endedStatuses;

export default itServiceSlice.reducer;