import React from 'react';

import { Descriptions } from 'antd';
import { ParsePhoneWhatsApp } from '../general/FormControls/FormsControls';

const ClientInfoTable = ({client}) => {

    if (!!client) {
        return (
            <>
                <Descriptions title="Информация о клиенте" bordered column={1} size='small' style={{marginBottom: '20px'}}>
                    <Descriptions.Item label="ФИО">{client.last_name} {client.first_name} {client.patronymic}</Descriptions.Item>
                    <Descriptions.Item label="Email">{client.email}</Descriptions.Item>
                    <Descriptions.Item label="Номер телефона"><ParsePhoneWhatsApp phone={client.phone_number}/></Descriptions.Item>
                </Descriptions>
                {client.type === 2 && !!client.company &&
                    <Descriptions title="Информация о компании" bordered column={1} size='small' style={{marginBottom: '20px'}}>
                        {client.company.registration_type === 'full' &&
                            <>
                                <Descriptions.Item label="Название">{client.company.type} "{client.company.name}"</Descriptions.Item>
                                <Descriptions.Item label="Юридический адрес">{client.company.law_address}</Descriptions.Item>
                                <Descriptions.Item label="БИН">{client.company.bin}</Descriptions.Item>
                                <Descriptions.Item label="Расчетный счет">{client.company.bank_account}</Descriptions.Item>
                                <Descriptions.Item label="БИК">{client.company.bic}</Descriptions.Item>                                
                                <Descriptions.Item label="Генеральный директор">{client.company.director}</Descriptions.Item>
                                <Descriptions.Item label="Телефон">{client.company.phone_number}</Descriptions.Item>
                                <Descriptions.Item label="Email">{client.company.email}</Descriptions.Item>
                                {!!client.company.address &&
                                    <Descriptions.Item label="Адрес">{client.company.address}</Descriptions.Item>
                                }
                                
                            </>
                        }
                        {client.company.registration_type === 'fast' &&
                            <>
                                <Descriptions.Item label="Название">{client.company.name}</Descriptions.Item>
                                <Descriptions.Item label="Реквизиты"><span dangerouslySetInnerHTML={{__html: client.company.requisites.replaceAll('\n', '<br/>')}}/></Descriptions.Item>
                                
                            </>
                        }
                    </Descriptions>
                }
            </>
        )
    }
    else return null;    
}

export default ClientInfoTable;