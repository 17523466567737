import { Col } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import CompanyPhones from '../general/CompanyPhones';

const FooterContacts = () => {
    return (
        <Col xs={24} md={5} className='footer_block'>
            <Title level={5}>Контакты</Title>
            <CompanyPhones/>
        </Col>
    )
}

export default FooterContacts;