import React, { useEffect } from 'react';

import { Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';

import { Link, useHistory } from 'react-router-dom';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import { getTaskDetail, selectLoading, selectTask } from '../../../../store/reducers/itServiceSlice';
import ServiceTaskInfoTable from './ServiceTaskInfoTable';

const { Title } = Typography;

const ServiceTaskDetailPage = (props) => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const id = props.match.params.taskId;

    const task = useSelector(selectTask);
    
    const isLoading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getTaskDetail(id));
    }, []);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    :
                    <>
                        {!!task ?
                            <>
                                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Заявка №{task.id}</Title>                                
                                <ServiceTaskInfoTable task={task}/>
                            </>
                            :
                            <>
                                <Title level={3}>У вас нет доступа к данной заявке</Title>
                            </>
                        }
                    </>
                }                
            </Col>
        </Row>
    )
}

export default ServiceTaskDetailPage;