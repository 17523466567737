import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Descriptions, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import Time from 'react-time-format';


import { selectUser } from '../../../store/reducers/authSlice';


const CompanyInfoPage = () => {

    const user = useSelector(selectUser);

    return (
        <Row>
            <Col span={24}>
                {!!user.company ?
                    <>
                        <Title level={2}>Информация о компании</Title>
                        <Descriptions bordered column={1}>
                            <Descriptions.Item label='Название'>{user.company.type} {user.company.name}</Descriptions.Item>
                            {user.company.registration_type === 'full' &&
                                <>                                
                                    <Descriptions.Item label="Юридический адрес">{user.company.law_address}</Descriptions.Item>
                                    <Descriptions.Item label="БИН">{user.company.bin}</Descriptions.Item>
                                    <Descriptions.Item label="Расчетный счет">{user.company.bank_account}</Descriptions.Item>
                                    <Descriptions.Item label="БИК">{user.company.bic}</Descriptions.Item>                                
                                    <Descriptions.Item label="Генеральный директор">{user.company.director}</Descriptions.Item>
                                    <Descriptions.Item label="Телефон">{user.company.phone_number}</Descriptions.Item>
                                    <Descriptions.Item label="Email">{user.company.email}</Descriptions.Item>
                                    {!!user.company.address &&
                                        <Descriptions.Item label="Адрес">{user.company.address}</Descriptions.Item>
                                    }
                                    
                                </>
                            }
                            {user.company.registration_type === 'fast' &&
                                <>                                
                                    <Descriptions.Item label="Реквизиты"><span dangerouslySetInnerHTML={{__html: user.company.requisites.replaceAll('\n', '<br/>')}}/></Descriptions.Item>
                                    
                                </>
                            }
                        </Descriptions>
                    </>
                    
                    :
                    <Title level={2} style={{textAlign: 'center'}}>Ваша компания не зарегистрирована</Title>
                }
            </Col>
        </Row>
    )
}

export default CompanyInfoPage;