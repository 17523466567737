import React from 'react';

import { Col, Row } from 'antd';
import MenuItem from '../../../components/general/MenuItem';
import Title from 'antd/lib/typography/Title';
import { HddOutlined } from '@ant-design/icons';

export const items = [
    {name: 'Договоры', link: '/cabinet/outsorcing/contracts', icon: <HddOutlined/>}
]

const OutsorcingPage = () => {

    return (        
        <Row gutter={16}>
            <Col span={24}>
                <Title level={4} style={{textAlign: 'center'}}>
                    IT Аутсорсинг
                </Title>
            </Col>
            {items.map(item => {
                return (
                    <MenuItem item={item}/>
                )
            })}            
        </Row>
    )
}

export default OutsorcingPage;