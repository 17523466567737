import { instance } from "./api";

export const authAPI = {
    login(data) {
      return instance.post('auth/login/', data);
    },
    logout() {
      return instance.post('auth/logout/');
    },
    register(data) {
      return instance.post('auth/users/registration/', data);
    },
    confirmRegistration(data) {
      return instance.post('auth/users/registration/confirmation/', data);
    },
    createPasswordForget(data) {
      return instance.post('auth/users/password-forget/', data);
    },
    confirmPasswordChange(data) {
      return instance.post('auth/users/password-forget/confirmation/', data);
    },
    refreshToken(refresh) {
      return instance.post('auth/token/refresh/', {refresh});
    },
    me() {
      return instance.get('auth/me');
    }
}