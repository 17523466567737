import React from 'react';

const YandexMapFrame = () => {
    return (
        <iframe 
            title='yandex-map' 
            src="https://yandex.ru/map-widget/v1/?um=constructor%3Aaa3df10f4f3ecaa7b6e01fdcaeb112a8a42d7067b8fc73c1e6bb354f43a43ff8&amp;source=constructor" 
            width="100%" 
            height="590" 
            frameborder="0"
            style={{paddingTop: '20px'}}
        />
    )
}

export default YandexMapFrame;