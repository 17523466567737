import { Button, Modal } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteArticle } from '../../../../store/reducers/articleSlice';

const ArticleDeleteModal = ({visible, setVisible, articleId}) => {
    const dispatch = useDispatch();

    const handleCancel = () => {
        setVisible(false);
    }

    const handleSubmit = () => {
        dispatch(deleteArticle(articleId));
        setVisible(false);
    }

    return (
        <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={[
            <Button type='primary' onClick={handleCancel}>
                Отмена
            </Button>,
            <Button type='primary' danger onClick={handleSubmit}>
                Удалить
            </Button>
        ]}
        >
            Вы действительно хотите удалить статью?
        </Modal>
    )
}

export default ArticleDeleteModal;