import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { createField, Input, Label, PasswordInput } from '../../components/general/FormControls/FormsControls';
import { confirmPasswordChange, createPasswordForget, selectLoading } from '../../store/reducers/authSlice';
import { setMessage } from '../../store/reducers/messageSlice';
import { EmailValidation, passwordMinLength, passwordOnlyNumbers, required } from '../../utils/validators/validators';

const password_forget_form = 'password_forget';
const password_forget_confirm_form = 'password_forget_confirm';

const PasswordForgetForm = ({handleSubmit, isLoading}) => {
    const [disabledTimer, setDisabledTimer] = useState(false);

    const dispatch = useDispatch();
    
    useEffect(() => {
        if (disabledTimer === true) {
            setTimeout(() => setDisabledTimer(false), 120 * 1000);
        }        
    }, [disabledTimer])

    const onSubmit = (formData) => {
        setDisabledTimer(true);
        dispatch(createPasswordForget(formData));
    }

    return (        
        <Form layout='vertical'>
            {createField('Email', 'email', [required, EmailValidation], Input, {label: Label('Email'), prefix: <MailOutlined/>})}
            <Form.Item style={{textAlign: 'center'}}>
                <Button type="primary" loading={isLoading} disabled={disabledTimer} onClick={handleSubmit(onSubmit)}>
                    Отправить код восстановления
                </Button>
            </Form.Item>            
        </Form>
    )
}

const PasswordForgetReduxForm = reduxForm({form: password_forget_form})(PasswordForgetForm);

const PasswordForgetConfirmForm = ({handleSubmit, isLoading}) => {
    
    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        if (formData.password === formData.confirm_password) {
            dispatch(confirmPasswordChange(formData));
        }
        else {
            dispatch(setMessage({message: ['Пароли должны совпадать'], type: 'error'}));
        }
        
    }

    return (        
        <Form layout='vertical'>
            {createField('Email', 'email', [required, EmailValidation], Input, {label: Label('Email'), prefix: <MailOutlined/>})}
            {createField('Код восстановления', 'token', [required], Input, {label: Label('Код восстановления')})}
            {createField('Новый пароль', "password", [required, passwordMinLength, passwordOnlyNumbers], PasswordInput, {prefix: <LockOutlined />, label: Label('Новый пароль')})}
            {createField('Повторите пароль', "confirm_password", [required, passwordMinLength, passwordOnlyNumbers], PasswordInput, {prefix: <LockOutlined />, label: Label('Повторите пароль')})}
            <Form.Item style={{textAlign: 'center'}}>
                <Button type="primary" loading={isLoading} onClick={handleSubmit(onSubmit)}>
                    Сменить пароль
                </Button>
            </Form.Item>
        </Form>
    )
}

const PasswordForgetConfirmReduxForm = reduxForm({form: password_forget_confirm_form})(PasswordForgetConfirmForm);

const PasswordForgetPage = () => {

    const isLoading = useSelector(selectLoading);

    return (
        <Row gutter={16}>
            <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}} xxl={{span: 8, offset: 8}}>
                <PasswordForgetReduxForm isLoading={isLoading}/>
                <PasswordForgetConfirmReduxForm isLoading={isLoading}/>
                <div style={{textAlign: 'center'}}>
                    Еще нет аккаунта? <Link to='/registration'>Зарегистрироваться</Link>
                </div>
                <div style={{textAlign: 'center'}}>
                    Уже сменили пароль? <Link to='/login'>Войти</Link>
                </div>
            </Col>
        </Row>
    )
}

export default PasswordForgetPage;