import { Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import { getTasks, selectHistoryTasks, selectLoading, selectTasks } from '../../../../store/reducers/itCalcSlice';
import ItCalcTaskTable from './ItCalcTaskTable';

const ItCalcTaskListPage = () => {
    const dispatch = useDispatch();

    const isLoading = useSelector(selectLoading);
    const tasks = useSelector(selectTasks);
    const historyTasks = useSelector(selectHistoryTasks);

    useEffect(() => {
        dispatch(getTasks());
    }, []);

    return (
        <Row gutter={16}>
            <Col span={24}>
            {isLoading ?
                <DataSkeleton/>
                : 
                <>
                    <Title level={2} style={{textAlign: 'center'}}>Заявки IT Калькулятора</Title>
                    <ItCalcTaskTable tasks={tasks}/>

                    <div style={{marginTop: '50px'}}>
                        <Title level={2} style={{textAlign: 'center'}}>История заявок</Title>
                        <ItCalcTaskTable tasks={historyTasks}/>
                    </div>
                </>
                
            }
            
                
            </Col>
        </Row>
    )
}

export default ItCalcTaskListPage;