import React, { useState } from 'react';
import moment from 'moment';

import { Button, Form } from 'antd';
import { LockOutlined, MailOutlined, PhoneOutlined, UserOutlined, PlusOutlined } from '@ant-design/icons';

import { useDispatch } from 'react-redux';
import { createField, Input, Label, RoleSelectInput, TypeSelectInput, PhoneInput, CompanySelectInput, DatePicker } from '../../../../components/general/FormControls/FormsControls';
import { reduxForm } from 'redux-form';
import { EmailValidation, OnlyLetters, passwordMinLength, passwordOnlyNumbers, phoneMinLength, required } from '../../../../utils/validators/validators';
import { createUser } from '../../../../store/reducers/adminSlice';
import { Link } from 'react-router-dom';

const form = 'user_create';

const UserCreateForm = ({handleSubmit, isLoading, companies}) => {

    const [birthDate, setBirthDate] = useState(null);

    const onDateChange = (date, dateString) => {
        let newDate = null;
        if (!!dateString) {
            newDate = moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD');
        }
        setBirthDate(newDate);
    }

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(createUser({
            ...formData,
            birth_date: birthDate,
            username: formData.email
        }));
        
    }


    return (
        <Form layout='vertical'>
            {createField('Электронный адрес', "email", [required, EmailValidation], Input, {prefix: <MailOutlined />, type: 'email', label: Label('Электронный адрес')})}
            {createField('Фамилия', "last_name", [required, OnlyLetters], Input, {prefix: <UserOutlined />, label: Label('Фамилия')})}
            {createField('Имя', "first_name", [required, OnlyLetters], Input, {prefix: <UserOutlined />, label: Label('Имя')})}
            {createField('Отчество', "patronymic", [OnlyLetters], Input, {prefix: <UserOutlined />, label: 'Отчество'})}
            {createField('+7 (7XX) XXX-XX-XX', 'phone_number', [phoneMinLength, required], PhoneInput, {prefix: <PhoneOutlined />, label: Label('Номер телефона')})}
            {createField('', 'role', [required], RoleSelectInput, {label: Label('Роль')})}
            {createField('', 'type', [], TypeSelectInput, {label: 'Тип лица'})}
            {createField('', 'company', [], CompanySelectInput, {companies: companies, label: 'Компания'})}
            <Link to='/cabinet/create-company'><PlusOutlined /> Создать компанию</Link>
            <DatePicker value={birthDate} onChange={onDateChange} placeholder='Дата рождения' label='Дата рождения'/>
            {createField('Пароль', "password", [required, passwordMinLength, passwordOnlyNumbers], Input, {prefix: <LockOutlined />, type: 'password', label: Label('Пароль')})}
            <Form.Item>
                <Button 
                    type="primary" 
                    loading={isLoading}
                    style={{marginTop: '20px'}}
                    onClick={handleSubmit(onSubmit)}>
                        Сохранить
                </Button>
            </Form.Item>
        </Form>
    )
}

export const UserCreateReduxForm = reduxForm({form: form})(UserCreateForm);