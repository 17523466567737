import { Button, Col, Form, Row, Switch } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createField, Input, PhoneInput } from '../../components/general/FormControls/FormsControls';
import { getTasksByPhoneOrEmail } from '../../store/reducers/itServiceSlice';
import { EmailValidation, phoneMinLength, required } from '../../utils/validators/validators';



const form = 'service_page';

const ServiceTaskForm = ({handleSubmit, isLoading}) => {

    const dispatch = useDispatch();

    const [switcher, setSwitcher] = useState(false);
    

    const onSubmit = (formData) => {
        if (switcher) {
            dispatch(getTasksByPhoneOrEmail({email: formData.email}));
        }
        else {
            dispatch(getTasksByPhoneOrEmail({phone_number: formData.phone_number}));
        }
        
    }

    return (
        <Form layout='horizontal' className='main_page__it_calc'>
            <Row gutter={16}>
                <Col span={24} style={{textAlign: 'center', marginBottom: '20px'}}>
                    <Title level={4}>
                        Узнать статус заявок
                    </Title>
                    <span style={{margin: '0 5px 0 0'}}>По номеру телефона</span>
                    <Switch 
                        style={{backgroundColor: '#175fa4'}} 
                        checked={switcher}
                        onChange={checked => setSwitcher(checked)}
                    />
                    <span style={{margin: '0 0 0 5px'}}>По адресу почты</span>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24} md={{span: 12, offset: 6}} lg={{span: 8, offset: 8}} xl={{span: 6, offset: 9}}>
                    {!switcher ?
                        <>
                            {createField('8 (XXX) XXX-XX-XX', 'phone_number', [required, phoneMinLength], PhoneInput, {})}
                        </>
                        :
                        <>
                            {createField('Email', 'email', [required, EmailValidation], Input, {})}
                        </>
                    }
                    
                    
                </Col>
                <Col xs={24} md={{span: 12, offset: 6}} lg={{span: 8, offset: 8}} xl={{span: 6, offset: 9}}
                    style={{textAlign: 'center', marginTop: '20px'}}>
                    <Button type="primary" loading={isLoading} onClick={handleSubmit(onSubmit)}>
                            Узнать статус заявок
                    </Button>
                </Col>
            </Row>
            
        </Form>
    )
}

export const ServiceTaskReduxForm = reduxForm({form: form})(ServiceTaskForm);