import React from 'react';

import { Button, Form } from 'antd';

import { useDispatch } from 'react-redux';
import { createField, NumberInput, Label, PercentInput } from '../../../../components/general/FormControls/FormsControls';
import { requiredNumber } from '../../../../utils/validators/validators';
import { reduxForm } from 'redux-form';
import { updateServiceDiscount } from '../../../../store/reducers/itCalcSlice';

const form = 'it_calc_service_edit';

const ItCalcServiceDiscountEditForm = ({handleSubmit, isLoading, serviceDiscount}) => {

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(updateServiceDiscount(serviceDiscount.id, formData));
    }

    return (
        <Form layout='vertical'>            
            {createField('', 'discount', [requiredNumber], PercentInput, {label: Label('Скидка (%)')})}
            {createField('', 'amount', [requiredNumber], NumberInput, {label: Label('Минимальное количество')})}
            <Form.Item>
                <Button type="primary" loading={isLoading}
                    onClick={handleSubmit(onSubmit)}>
                        Сохранить
                </Button>
            </Form.Item>
        </Form>
    )
}

export const ItCalcServiceDiscountEditReduxForm = reduxForm({form: form})(ItCalcServiceDiscountEditForm);