import { CalculatorOutlined, FileWordOutlined, HddOutlined, HomeOutlined, LaptopOutlined, OrderedListOutlined, TeamOutlined, ToolOutlined, UnorderedListOutlined, UserOutlined } from "@ant-design/icons"

export const common_items = [
    {name: 'Личный кабинет', link: '/cabinet', icon: <HomeOutlined />}    
]

export const user_items = [
    {name: 'Мои заявки', link: '/cabinet/tasks', icon: <UnorderedListOutlined />},
    {name: 'Быстрые заявки', link: '/cabinet/fast-tasks', icon: <OrderedListOutlined />}, 
]

export const manager_items = [
    {name: 'Заявки', link: '/cabinet/admin-tasks', icon: <UnorderedListOutlined />},
    {name: 'Быстрые заявки', link: '/cabinet/fast-tasks', icon: <OrderedListOutlined />},
    {name: 'IT Калькулятор', link: '/cabinet/it-calc-tasks', icon: <CalculatorOutlined />},
    {name: 'Возврат/Ремонт', link: '/cabinet/service-tasks', icon: <ToolOutlined />}
]

export const corp_manager_items = [
    {name: 'Заявки', link: '/cabinet/admin-outsorcing-tasks', icon: <UnorderedListOutlined />},
]

export const admin_items = [
    {name: 'Пользователи', link: '/cabinet/users', icon: <UserOutlined/>},
    {name: 'Специалисты', link: '/cabinet/specialists', icon: <LaptopOutlined />},
    {name: 'Компании', link: '/cabinet/companies', icon: <TeamOutlined />},
    {name: 'Услуги', link: '/cabinet/services', icon: <HddOutlined />},
    {name: 'IT Калькулятор', link: '/cabinet/it-calc-services', icon: <CalculatorOutlined />},
    {name: 'Статьи', link: '/cabinet/articles', icon: <FileWordOutlined />}
]

export const data_items = [
    {name: 'Пользователь', link: '/cabinet/user-info-page', icon: <UserOutlined/>},
    {name: 'Компания', link: '/cabinet/company-info-page', icon: <TeamOutlined/>}
]