import React, { useEffect } from 'react';

import { Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';

import { getService, selectLoading, selectService, selectSpecialists } from '../../../../store/reducers/taskSlice';
import { Link, useHistory } from 'react-router-dom';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import { ServiceEditReduxForm } from './ServiceEditReduxForm';

const { Title } = Typography;

const ServiceEditPage = (props) => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const id = props.match.params.serviceId;

    const service = useSelector(selectService);
    const specialists = useSelector(selectSpecialists);
    const isLoading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getService(id));
    }, []);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col xs={24} md={22} lg={18} xl={14} xxl={10}>
                {isLoading ?
                    <DataSkeleton/>
                    :
                    <>
                        {!!service ?
                            <>
                                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Услуга №{service.id}</Title>
                                <ServiceEditReduxForm 
                                    isLoading={isLoading} 
                                    service={service} 
                                    specialists={specialists} 
                                    initialValues={{...service}} 
                                />
                            </>
                            :
                            <>
                                <Title level={3}>У вас нет доступа к данной странице</Title>
                            </>
                        }
                    </>
                }
                
            </Col>
        </Row>
    )
}

export default ServiceEditPage;