import React from 'react';

import { Table } from 'antd';
import { Link } from 'react-router-dom';

const { Column } = Table;

const ItCalcServiceTable = ({services}) => {

    return (
        <Table 
            dataSource={services}
            pagination={{position: ['topRight'], defaultCurrent: 1, defaultPageSize: 10, total: services.length}} 
            size='small'
            scroll={{x: true}} 
            locale={{filterReset: 'Очистить', filterConfirm: 'Применить', emptyText: 'Услуги отсутствуют'}}
        >
            <Column
                title="Название" 
                dataIndex="label" 
                key="label"
                render={(label, record) => <Link to={`/cabinet/it-calc-services/${record.id}`}>{label}</Link>}
            />
            <Column
                title="Стоимость за единицу" 
                dataIndex="unit_price" 
                key="unit_price"
                render={unit_price => <>{parseInt(unit_price)} тг</>}
            />
            <Column
                title="Тип поля" 
                dataIndex="input_type_display" 
                key="input_type_display"                
            />
            <Column
                title="Количество по умолчанию" 
                dataIndex="default_amount" 
                key="default_amount"
                render={(default_amount, record) => `${default_amount} ${record.value_type_display}`}
            />
            <Column
                title="Минимальное количество" 
                dataIndex="min_amount" 
                key="min_amount"
                render={(min_amount, record) => `${min_amount} ${record.value_type_display}`}
            />
            <Column
                title="Максимальное количество" 
                dataIndex="max_amount" 
                key="max_amount"
                render={(max_amount, record) => `${max_amount} ${record.value_type_display}`}
            />
            <Column
                title="Скидки" 
                dataIndex="discounts" 
                key="discounts"
                render={(discounts, record) => {
                    return (
                        <>
                            {discounts.length > 0 ?
                                <>
                                    {discounts.map(discount => <div>{discount.discount}% от {discount.amount} {record.value_type_display};</div>)}
                                </>
                                :
                                <>отсутствуют</>
                            }
                        </>
                    )
                }}
            />
        </Table>
    )
}

export default ItCalcServiceTable;