import React, { useEffect } from 'react';

import { Button, Col, Row } from 'antd';
import { Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { getServices, selectLoading, selectServices } from '../../../../store/reducers/taskSlice';
import { useDispatch, useSelector } from 'react-redux';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import ServiceTable from './ServiceTable';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const ServiceListPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getServices());
    }, []);

    const services = useSelector(selectServices);
    const isLoading = useSelector(selectLoading);

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    : 
                    <>
                        <Title level={2} style={{textAlign: 'center'}}>Услуги</Title>
                        <ServiceTable services={services}/>
                        <Button type='primary' style={{marginTop: '10px'}}>
                            <Link to='/cabinet/create-service'><PlusOutlined /> Создать услугу</Link>
                        </Button>
                    </>                
                }
            </Col>
        </Row>
    )
}

export default ServiceListPage;