import React from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const Loader = () => {
    return (
        <div style={{margin: '50px'}}>
            <Spin indicator={antIcon}/>
        </div>
        
    )
}

export default Loader;