import React from 'react';
import { Col } from 'antd';
import CompanyAddress from '../general/CompanyAddress';
import Title from 'antd/lib/typography/Title';

const FooterAddress = () => {
    return (
        <Col xs={24} md={5} lg={3} className='footer_block'>
            <Title level={5}>Адрес</Title>
            <CompanyAddress/>
        </Col>
    )
}

export default FooterAddress;