import React from 'react';

import { Table } from 'antd';
import { Link } from 'react-router-dom';
import Time from 'react-time-format';

const { Column } = Table;

const CompanyTable = ({companies}) => {

    return (
        <Table 
            dataSource={companies} 
            pagination={{position: ['topRight'], defaultCurrent: 1, defaultPageSize: 10, total: companies.length}} 
            size='small' 
            scroll={{x: true}} 
            locale={{filterReset: 'Очистить', filterConfirm: 'Применить', emptyText: 'Компании отсутствуют'}}
        >
            <Column
                title="id" 
                dataIndex="id" 
                key="id"                 
            />
            <Column
                title="Название компании" 
                dataIndex="name" 
                key="name"
                render={(name, record) => <Link to={`/cabinet/companies/${record.id}`}>{name}</Link>}                 
            />
            <Column
                title="Тип регистрации" 
                dataIndex="registration_type" 
                key="registration_type"
                render={(registration_type) => registration_type === 'fast' ? 'Быстрая' : 'Полная'}                 
            />
            <Column 
                title="Дата создания" 
                dataIndex="created_time" 
                key="created_time" 
                render={created_time => <Time value={created_time} format='DD.MM.YYYY hh:mm'/>}
            />
            <Column 
                title="Дата изменения" 
                dataIndex="updated_time" 
                key="updated_time" 
                render={updated_time => <Time value={updated_time} format='DD.MM.YYYY hh:mm'/>}
            />
        </Table>
    )
}

export default CompanyTable;