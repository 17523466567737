import React, { useEffect } from 'react';

import { Button, Col, Row } from 'antd';
import { Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons'

import { useDispatch, useSelector } from 'react-redux';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import { Link } from 'react-router-dom';
import { getTasks, selectCurrentStatuses, selectEndedStatuses, selectHistoryTasks, selectLoading, selectTasks } from '../../../../store/reducers/itServiceSlice';
import ServiceTaskTable from './ServiceTaskTable';

const { Title } = Typography;

const ServiceTaskListPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTasks());
    }, [])
    
    const tasks = useSelector(selectTasks);
    const historyTasks = useSelector(selectHistoryTasks);
    const statuses = useSelector(selectCurrentStatuses);
    const endedStatuses = useSelector(selectEndedStatuses);

    const isLoading = useSelector(selectLoading);

    return (
        <Row gutter={16}>
            <Col span={24}>
            {isLoading ?
                <DataSkeleton/>
                : 
                <>                        
                    <Title level={2} style={{textAlign: 'center'}}>Заявки на Ремонт/Возврат</Title>
                    <ServiceTaskTable tasks={tasks} statuses={statuses}/>
                    <Button type='primary' style={{marginTop: '10px'}}>
                        <Link to='/cabinet/create-it-service-task'><PlusOutlined /> Создать заявку</Link>
                    </Button>
                    <div style={{marginTop: '50px'}}>
                        <Title level={2} style={{textAlign: 'center'}}>История заявок</Title>
                        <ServiceTaskTable tasks={historyTasks} statuses={endedStatuses}/>
                    </div>
                </>
                
            }
            
                
            </Col>
        </Row>
    )
}

export default ServiceTaskListPage;