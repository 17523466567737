import React, { useState } from 'react';

import { Button, Table } from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Time from 'react-time-format';
import ArticleDeleteModal from './ArticleDeleteModal';

const { Column } = Table;

const ArticleTable = ({articles}) => {
    const [visible, setVisible] = useState(false);
    const [articleId, setArticleId] = useState(0);

    const handleDelete = (id) => {
        setArticleId(id);
        setVisible(true);
    }

    return (
        <>
            <Table 
                dataSource={articles} 
                pagination={{position: ['topRight'], defaultCurrent: 1, defaultPageSize: 10, total: articles.length}} 
                size='small' 
                scroll={{x: true}} 
                locale={{filterReset: 'Очистить', filterConfirm: 'Применить', emptyText: 'Статьи отсутствуют'}}
            >
                <Column
                    title="Название" 
                    dataIndex="name" 
                    key="name"
                    render={(name, record) => <Link to={`/articles/${record.id}`}>{name}</Link>}
                />
                <Column
                    title="Рубрика" 
                    dataIndex={['rubric', 'name']} 
                    key="rubric"
                />
                <Column 
                    title="Дата создания" 
                    dataIndex="created_time" 
                    key="created_time" 
                    render={created_time => <Time value={created_time} format='DD.MM.YYYY hh:mm'/>}
                />
                <Column 
                    title="Дата изменения" 
                    dataIndex="updated_time" 
                    key="updated_time" 
                    render={updated_time => <Time value={updated_time} format='DD.MM.YYYY hh:mm'/>}
                />
                <Column
                    title="" 
                    dataIndex="id" 
                    key="id"
                    render={id => {
                        return (
                            <Button type='primary'>
                                <Link to={`/cabinet/articles/${id}`}><EditOutlined /></Link>
                            </Button>
                        )
                    }}
                />
                <Column
                    title="" 
                    dataIndex="id" 
                    key="id"
                    render={id => {
                        return (
                            <Button type='primary' danger onClick={e => handleDelete(id)}>
                                <DeleteOutlined />
                            </Button>
                        )
                    }}
                />
            </Table>
            <ArticleDeleteModal visible={visible} setVisible={setVisible} articleId={articleId}/>
        </>
    )
}

export default ArticleTable;