import history from "../../routes/history";
import { logout } from "./authSlice";
import { setMessage } from "./messageSlice";

export const errorHandle = (error, dispatch) => {
    if (error.response.status === 401) {
        dispatch(logout());
    }
    if ([403, 404].includes(error.response.status)) {
        history.push('/');
    }
    const errors = error.response.data;
    let messages = [];
    if ('detail' in errors) {
        messages.push(errors.detail);
    } else {
        for (let err in errors) {
            for (let i of errors[err]) {
                messages.push(i);
            }
        }
    }
    
    dispatch(setMessage({message: messages, type: 'error'}));
}