import React from 'react';

import { Descriptions } from 'antd';
import { ParsePhoneWhatsApp } from '../../../../components/general/FormControls/FormsControls';

const ItCalcClientInfoTable = ({task}) => {

    if (!!task) {
        return (
            <>  
                
                <Descriptions title="Информация о клиенте" bordered column={1} size='small' style={{marginBottom: '20px'}}>
                    <Descriptions.Item label="ФИО">{task.client_name}</Descriptions.Item>
                    <Descriptions.Item label="Email">{task.email}</Descriptions.Item>
                    <Descriptions.Item label="Номер телефона"><ParsePhoneWhatsApp phone={task.phone_number}/></Descriptions.Item>
                </Descriptions>
            </>
        )
    }
    else return null;    
}

export default ItCalcClientInfoTable;