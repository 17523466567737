import { Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { BugOutlined, ShoppingCartOutlined } from "@ant-design/icons";

const HeaderMenu = ({mode='inline'}) => {

    
    return (
        <Menu selectable={false} mode={mode} className='header_menu'>
            <Menu.Item key='/ticket' icon={<ShoppingCartOutlined />} 
                className='header_menu_item'
            >
                <a href="https://rs6.kz" target="_blank" rel="noreferrer">
                    Наш Интернет-магазин
                </a>
            </Menu.Item> 
            <Menu.Item key='/service' icon={<BugOutlined/>}
                className='header_menu_item'
            >
                <Link to='/it-service'>Возврат/ремонт</Link>
            </Menu.Item>
                       
        </Menu>
    )
}

export default HeaderMenu;