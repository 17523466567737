import React from 'react';
import {  useLocation } from 'react-router-dom';

import { Layout } from 'antd';
import CabinetMenu from './CabinetMenu';

const { Sider } = Layout;

const CabinetSider = () => {    
    const location = useLocation();
    if (location.pathname.match('/cabinet')) {
        return (
            <Sider
                breakpoint='lg'
                collapsedWidth={0}
                trigger={null}
            >
                <CabinetMenu/>
            </Sider>
        );
    }
    else return null;
}

export default CabinetSider;