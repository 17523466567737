import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { logout, selectLoading, selectLoggedIn, selectUser } from '../../store/reducers/authSlice';
import { Link } from 'react-router-dom';

const LoginButtonGroup = () => {

    const isLoggedIn = useSelector(selectLoggedIn);
    const user = useSelector(selectUser);
    const isLoading = useSelector(selectLoading);

    const dispatch = useDispatch();

    if (isLoggedIn) {
        return (
            <div className="login_button_group">
                {!!user &&
                <span className="user__username">
                    {user.username}
                </span>
                }
                <Link to='/cabinet' className="personal_cabinet_button">Личный кабинет</Link>
                <Button type="primary" className="logout_button"
                onClick={() => dispatch(logout())} loading={isLoading}>
                    <Link to='/'><ArrowLeftOutlined /> Выйти</Link>
                </Button>
            </div>
        );
    }
    else {
        return (
            <div className="login_button_group">
                <Button type='primary' className="login_button">
                    <Link to='/login'><ArrowRightOutlined /> Вход</Link>
                </Button>
                <br/>
                <Link to='/registration'>Регистрация</Link>               
            </div>
            
        );
    }
}

export default LoginButtonGroup;