import React, { useEffect } from 'react';

import { Col, Descriptions, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import Time from 'react-time-format';

import { Link, useHistory } from 'react-router-dom';
import { getSpecialist, selectLoading, selectSpecialist } from '../../../../store/reducers/adminSlice';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import { selectServices } from '../../../../store/reducers/taskSlice';
import { SpecialistEditReduxForm } from './SpecialistEditReduxForm';
import { ParsePhoneWhatsApp } from '../../../../components/general/FormControls/FormsControls';

const { Title } = Typography;

const SpecialistEditPage = (props) => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const id = props.match.params.specId;

    const specialist = useSelector(selectSpecialist);
    const services = useSelector(selectServices);
    const isLoading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getSpecialist(id));
    }, []);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    :
                    <>
                        {!!specialist ?
                            <>
                                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Специалист №{specialist.id}</Title>
                                <Descriptions title='Информация о пользователе' bordered column={1} size='small' style={{marginBottom: '20px'}}>
                                    <Descriptions.Item label='Имя пользователя/Email'>
                                        <Link to={`/cabinet/users/${specialist.user?.id}`}>{specialist.user?.username}</Link>
                                    </Descriptions.Item>
                                    <Descriptions.Item label='ФИО'>
                                        {specialist.user?.last_name} {specialist.user?.first_name} {specialist.user?.patronymic}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Номер телефона'>
                                        <ParsePhoneWhatsApp phone={specialist.user?.phone_number}/>
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Дата регистрации'><Time value={specialist.user?.date_joined} format='DD.MM.YYYY hh:mm'/></Descriptions.Item>
                                    <Descriptions.Item label='Последний вход'><Time value={specialist.user?.last_login} format='DD.MM.YYYY hh:mm'/></Descriptions.Item>
                                    
                                </Descriptions>
                                <SpecialistEditReduxForm  
                                    specialist={specialist}
                                    services={services}
                                    isLoading={isLoading}
                                />
                            </>
                            :
                            <>
                                <Title level={3}>У вас нет доступа к данной странице</Title>
                            </>
                        }
                    </>
                }
                
            </Col>
        </Row>
    )
}

export default SpecialistEditPage;