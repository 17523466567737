import React, { useState } from 'react';

import { Button, Form } from 'antd';

import { useDispatch } from 'react-redux';
import { Label, ServicesSelect } from '../../../../components/general/FormControls/FormsControls';
import { reduxForm } from 'redux-form';
import { updateSpecialist } from '../../../../store/reducers/taskSlice';

const form = 'specialist_edit';

const SpecialistEditForm = ({handleSubmit, isLoading, specialist, services}) => {

    const servicesSelected = () => {
        const specServices = services.filter(service => specialist.services?.includes(service.id));
        return specServices.map(service => service.id);
    }
        
    const [specServices, setSpecServices] = useState(servicesSelected);

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(updateSpecialist(specialist.id, {services: specServices}));
    }

    const onServiceChange = (value) => {
        setSpecServices(value);
    }

    return (
        <Form layout='vertical'>
            <ServicesSelect
                services={services} 
                label={Label('Услуги')} 
                placeholder='Выберите одну или несколько услуг'
                value={specServices}
                onChange={onServiceChange}
            />
            <Form.Item>
                <Button type="primary" loading={isLoading}
                    onClick={handleSubmit(onSubmit)}>
                        Сохранить
                </Button>
            </Form.Item>
        </Form>
    )
}

export const SpecialistEditReduxForm = reduxForm({form: form})(SpecialistEditForm);