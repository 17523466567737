import React from 'react';
import { useSelector } from 'react-redux';

import { Timeline } from 'antd';
import Time from 'react-time-format';
import { selectUserRole } from '../../store/reducers/authSlice';


const TaskDetailTimeline = ({task}) => {
    const userRole = useSelector(selectUserRole);

    return (
        <Timeline mode='left' style={{marginTop: '30px'}}>
            {!!task.task_progress && 
            task.task_progress.map((progress) => {
                if (userRole === 'client') {
                    return (
                        <Timeline.Item color={progress.client_read ? 'blue' : 'red'}>
                            <Time value={progress.created_time} format='DD.MM.YYYY hh:mm' style={{fontWeight: '700'}} /> {progress.user.role_display} {progress.user.last_name} {progress.user.first_name} {progress.message}
                            {!!progress.commentary &&
                                <div><span style={{fontWeight: '700'}}>Сообщение:</span> {progress.commentary}</div>
                            }
                        </Timeline.Item>
                    )
                }
                else if (['admin', 'manager'].includes(userRole)) {
                    return (
                        <Timeline.Item color={progress.admin_read ? 'blue' : 'red'}>
                            <Time value={progress.created_time} format='DD.MM.YYYY hh:mm' style={{fontWeight: '700'}} /> {progress.user.role_display} {progress.user.last_name} {progress.user.first_name} {progress.message}
                            {!!progress.commentary &&
                                <div><span style={{fontWeight: '700'}}>Сообщение:</span> {progress.commentary}</div>
                            }
                        </Timeline.Item>
                    )
                }
                else if (userRole === 'specialist') {
                    return (
                        <Timeline.Item color={progress.specialist_read ? 'blue' : 'red'}>
                            <Time value={progress.created_time} format='DD.MM.YYYY hh:mm' style={{fontWeight: '700'}} /> {progress.user.role_display} {progress.user.last_name} {progress.user.first_name} {progress.message}
                            {!!progress.commentary &&
                                <div><span style={{fontWeight: '700'}}>Сообщение:</span> {progress.commentary}</div>
                            }
                        </Timeline.Item>
                    )
                }
                else return null;                
            })}
        </Timeline>
    )
}

export default TaskDetailTimeline;