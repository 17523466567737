import { instance } from "./api";

export const itCalcAPI = {
    createTask(data) {
      return instance.post('it_calc/tasks/', data);
    },
    getTasks() {
      return instance.get('it_calc/tasks/');
    },
    getTaskDetail(id) {
      return instance.get(`it_calc/tasks/${id}/`);
    },
    updateTaskStatus(data) {
      return instance.post('it_calc/update-task-status/', data);
    },
    getServices() {
      return instance.get('it_calc/services/');
    },
    getService(id) {
      return instance.get(`it_calc/services/${id}/`);
    },  
    updateService(id, data) {
      return instance.post(`it_calc/services/${id}/`, data);
    },
    getServiceDiscount(id) {
      return instance.get(`it_calc/service-discounts/${id}/`);
    },
    updateServiceDiscount(id, data) {
      return instance.post(`it_calc/service-discounts/${id}/`, data);
    },
    deleteServiceDiscount(id) {
      return instance.delete(`it_calc/service-discounts/${id}/`);
    },
    createServiceDiscount(data) {
      return instance.post('it_calc/service-discounts/', data);
    }
}