import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getServices, selectCreationLoading, selectLoading, selectServices } from '../../store/reducers/itCalcSlice';
import { ItCalculatorReduxForm } from './ItCalculatorReduxForm';

import Loader from '../general/Loader';
import { selectUser } from '../../store/reducers/authSlice';

const ItCalculator = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getServices());
    }, []);

    const user = useSelector(selectUser);
    const services = useSelector(selectServices);

    const isCreationLoading = useSelector(selectCreationLoading);
    const isLoading = useSelector(selectLoading);

    return (
        <>
            {isLoading ?
                <div style={{textAlign: 'center'}}>
                    <Loader/>
                </div>
                :
                <>
                    {services.length > 0 &&
                        <ItCalculatorReduxForm isLoading={isCreationLoading} services={services} 
                            initialValues={{
                                client_name: !!user ? `${user.last_name} ${user.first_name}` : '',
                                phone_number: user?.phone_number,
                                email: user?.email
                            }}
                        />
                    }
                </>
            }
        </>
        
        
            
        
    )
}

export default ItCalculator;