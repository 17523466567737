import React, { useState } from 'react';

import LoginButtonGroup from './LoginButtonGroup';
import { Button, Drawer, Layout } from 'antd';
import { MenuOutlined } from "@ant-design/icons";
import CabinetMenu from '../CabinetSider/CabinetMenu';
import HeaderMenu from './HeaderMenu';
import Logo from '../general/Logo';
import CompanyPhones from '../general/CompanyPhones';
import CompanyAddress from '../general/CompanyAddress';

const { Header } = Layout;

const MainHeader = () => {
    const [visible, setVisible] = useState(false);
    

    return (
        <Header className='main_header'>
            <Button
                className="menu_button"
                size='large'
                icon={<MenuOutlined />}
                onClick={() => setVisible(true)}
            />
            <Drawer
                title={<Logo/>}
                placement="left"
                width={300}
                onClick={() => setVisible(false)}
                onClose={() => setVisible(false)}
                visible={visible}
                className="drawer"
            >                
                <CabinetMenu theme='light'/>
                <HeaderMenu/>
                <CompanyPhones/>
                <CompanyAddress/>
            </Drawer>
            <Logo/>
            <LoginButtonGroup/>
            <CompanyAddress/>
            <CompanyPhones/>
            <HeaderMenu mode='horizontal'/>            
        </Header>
    )
}

export default MainHeader;