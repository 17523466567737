import { Skeleton } from 'antd';
import React from 'react';

const DataSkeleton = () => {
    return (
        <>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
        </>
    )
}

export default DataSkeleton;