import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Descriptions, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import Time from 'react-time-format';


import { selectUser } from '../../../store/reducers/authSlice';


const UserInfoPage = () => {

    const user = useSelector(selectUser);

    return (
        <Row>
            <Col span={24}>
                <Title level={2}>Информация о пользователе</Title>
                <Descriptions bordered column={1}>
                    <Descriptions.Item label='ФИО'>{user.last_name} {user.first_name} {user.patronymic}</Descriptions.Item>
                    <Descriptions.Item label='Email'>{user.email}</Descriptions.Item>
                    <Descriptions.Item label='Дата регистрации'><Time value={user.date_joined} format='DD.MM.YYYY hh:mm'/></Descriptions.Item>
                    <Descriptions.Item label='Дата изменения'><Time value={user.updated_time} format='DD.MM.YYYY hh:mm'/></Descriptions.Item>
                    <Descriptions.Item label='Номер телефона'>{user.phone_number}</Descriptions.Item>
                    {!!user.birth_date &&
                        <Descriptions.Item label='Дата рождения'><Time value={user.birth_date} format='DD.MM.YYYY'/></Descriptions.Item>
                    }
                    {!!user.type &&
                        <Descriptions.Item label='Тип лица'>{user.type === 1 ? 'Физическое лицо' : 'Юридическое лицо'}</Descriptions.Item>
                    }
                </Descriptions>
            </Col>
        </Row>
    )
}

export default UserInfoPage;