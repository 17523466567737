import React, { useEffect, useState } from 'react';

import { Table } from 'antd';
import Time from 'react-time-format';
import Title from 'antd/lib/typography/Title';

const { Column } = Table;

const ClientTaskDetailTable = ({task}) => {

    const [costSum, setCostSum] = useState(0);

    useEffect(() => {
        if (!!task.task_services) {
            let sum = 0;
            task.task_services.forEach(service => sum += parseInt(service.price));
            setCostSum(sum);
        }
    }, [task]);

    return (
        <>
            <Table 
                dataSource={task.task_services} 
                pagination={false}
                expandable={{
                    expandedRowRender: record => <p style={{ margin: 0 }}>{record.service.description}</p>,
                    rowExpandable: record => record.service.name,
                }}
                rowKey='id'
                size='small'
                scroll={{x: true}}
                title={() => <Title level={5} style={{fontWeight: 700}}>Услуги</Title>}
            >
                <Column 
                    title="Услуга"
                    key="name"
                    dataIndex={['service', 'name']}
                />
                <Column
                    title="Стоимость услуги"
                    dataIndex="price"
                    key="price"
                    render={price => <>{parseInt(price)} тг</>}
                />
                <Column 
                    title="Специалист" 
                    dataIndex="specialist" 
                    key="specialist"
                    render={specialist => {
                        if (!!specialist) return <>{specialist.user.email}:{specialist.user.last_name} {specialist.user.first_name}</>;
                        else return <>не назначен</>;
                    }}
                />
                <Column 
                    title="Дата изменения" 
                    dataIndex="updated_time" 
                    key="updated_time"
                    render={updated_time => <Time value={updated_time} format='DD.MM.YYYY hh:mm'/>}
                />
            </Table>
        <Title level={5} style={{textAlign: 'right', marginTop: '10px'}}>Общая стоимость - {costSum} тг</Title>
        </>
    )
}

export default ClientTaskDetailTable;