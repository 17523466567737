import { createSlice } from '@reduxjs/toolkit';

export const messageSlice = createSlice({
    name: 'message',
    initialState: {
        message: null,
        type: null,
    },
    reducers: {
        setMessage: (state, action) => {
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
        deleteMessage: state => {
            state.message = null;
            state.type = null;
        }
    }
});

export const { setMessage, deleteMessage } = messageSlice.actions;

export const selectMessage = state => state.message.message;
export const selectType = state => state.message.type;


export default messageSlice.reducer;