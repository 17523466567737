import React from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { selectUserRole } from '../../store/reducers/authSlice';
import { Link, useLocation } from 'react-router-dom';

import { admin_items, common_items, data_items, manager_items, user_items } from '../../pages/Cabinet/CabinetPage/CabinetMenuItems';

const CabinetMenu = ({theme='dark'}) => {
    const user_role = useSelector(selectUserRole);
    const location = useLocation();

    if (location.pathname.match('/cabinet')) {
        return (
            <Menu theme={theme} defaultSelectedKeys={[location.pathname]} mode="inline">
                {common_items.map(item => {
                            return (
                                <Menu.Item key={item.link} icon={item.icon}>
                                    <Link to={item.link}>{item.name}</Link>
                                </Menu.Item>
                            )
                        })}
                {['client', 'specialist'].includes(user_role) &&
                    <>  
                        {user_items.map(item => {
                            return (
                                <Menu.Item key={item.link} icon={item.icon}>
                                    <Link to={item.link}>{item.name}</Link>
                                </Menu.Item>
                            )
                        })}                        
                    </>
                }
                {['manager', 'admin'].includes(user_role) &&
                    <>
                        {manager_items.map(item => {
                            return (
                                <Menu.Item key={item.link} icon={item.icon}>
                                    <Link to={item.link}>{item.name}</Link>
                                </Menu.Item>
                            )
                        })} 
                    </>
                }
                {user_role === 'admin' && 
                    <Menu.ItemGroup key="admin-panel" title="Админ-панель">
                        {admin_items.map(item => {
                            return (
                                <Menu.Item key={item.link} icon={item.icon}>
                                    <Link to={item.link}>{item.name}</Link>
                                </Menu.Item>
                            )
                        })}
                    </Menu.ItemGroup>
                }
                <Menu.ItemGroup key="user-data" title="Данные">
                    {data_items.map(item => {
                        return (
                            <Menu.Item key={item.link} icon={item.icon}>
                                <Link to={item.link}>{item.name}</Link>
                            </Menu.Item>
                        )
                    })}
                </Menu.ItemGroup>
            </Menu>
        )
    }
    else return null;
}

export default CabinetMenu;