import React, { useEffect } from 'react';

import { Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';

import { getSpecialists, selectLoading, selectSpecialists } from '../../../../store/reducers/taskSlice';
import { Link, useHistory } from 'react-router-dom';
import { ServiceCreateReduxForm } from './ServiceCreateReduxForm';

const { Title } = Typography;

const ServiceCreatePage = () => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const specialists = useSelector(selectSpecialists);
    const isLoading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getSpecialists());
    }, []);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col xs={24} md={22} lg={18} xl={14} xxl={10}>
                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Создание услуги</Title>
                <ServiceCreateReduxForm 
                    isLoading={isLoading}                              
                    specialists={specialists} 
                />
            </Col>
        </Row>
    )
}

export default ServiceCreatePage;