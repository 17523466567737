import { createSlice } from '@reduxjs/toolkit';
import { reset } from 'redux-form';
import { authAPI } from '../../api/authAPI';
import history from '../../routes/history';
import { errorHandle } from './errorHandler';
import { setMessage } from './messageSlice';
import { removeTasks } from './taskSlice';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoggedIn: false,
        isInitialized: false,
        isLoading: false,
        user: null,
        refresh: ''
    },
    reducers: {
        setUserData: (state, action) => {
            state.user = action.payload;
            state.isLoggedIn = true;
        },
        setRefresh: (state, action) => {
            state.refresh = action.payload;
        },
        deleteUserData: state => {
            state.user = null;
            state.isLoggedIn = false;
            state.isLoading = false;
            state.refresh = '';
        },
        setInitialized: state => {
            state.isInitialized = true;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    }
});

export const { setUserData, setRefresh, deleteUserData, setInitialized, setLoading } = authSlice.actions;

export const login = (data) => dispatch => {
    dispatch(setLoading(true));
    authAPI.login(data)
    .then((response) => {
        if (response.status === 200) {
            dispatch(getUserData());
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    });
}

export const logout = () => dispatch => {
    dispatch(setLoading(true));
    authAPI.logout()
    .then((response) => {
        if (response.data.detail) {
            dispatch(setLoading(false));
            dispatch(deleteUserData());
            dispatch(removeTasks());
        }
    });
}

export const refreshToken = (refresh) => dispatch => {
    authAPI.refreshToken(refresh);
}

export const getUserData = () => dispatch => {    
    authAPI.me()
    .then((response) => {
        if (response.status === 200) {
            dispatch(setLoading(false));
            dispatch(setUserData(response.data.user));
            dispatch(setInitialized());
            dispatch(setRefresh(response.data.refresh));
        }
    })
    .catch((error) => {
        if (error.response.status === 401) {
            dispatch(setLoading(false));
            dispatch(setInitialized());
        }
    })
}

export const register = (data) => dispatch => {
    dispatch(setLoading(true));
    authAPI.register(data)
    .then((response) => {
        if (response.status === 201) {
            dispatch(setMessage({message: ['Пользователь успешно создан. Ожидайте, на ваш Email отправлено письмо с кодом для подтверждения'], type: 'success'}));
            dispatch(reset('registration'));
            dispatch(setLoading(false));
            history.push(`/registration/confirmation/?email=${data.email}`);
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const confirmRegistration = (data) => dispatch => {
    dispatch(setLoading(true));
    authAPI.confirmRegistration(data)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setMessage({message: ['Регистрация успешно подтверждена'], type: 'success'}));
            dispatch(setLoading(false));
            history.push('/login');
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const createPasswordForget = (data) => dispatch => {
    dispatch(setLoading(true));
    authAPI.createPasswordForget(data)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setMessage({message: ['Код подтверждения придет на почту в течение двух минут'], type: 'success'}));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const confirmPasswordChange = (data) => dispatch => {
    dispatch(setLoading(true));
    authAPI.confirmPasswordChange(data)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setMessage({message: ['Пароль успешно изменен'], type: 'success'}));
            dispatch(setLoading(false));
            history.push('/login');
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const selectLoading = state => state.auth.isLoading;
export const selectUser = state => state.auth.user;
export const selectLoggedIn = state => state.auth.isLoggedIn;
export const selectUserRole = state => state.auth.user?.role;
export const selectInitialized = state => state.auth.isInitialized;
export const selectRefresh = state => state.auth.refresh;

export default authSlice.reducer;

