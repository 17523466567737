import React from 'react';
import { Link } from 'react-router-dom';

import { Badge, Table } from 'antd';
import Time from 'react-time-format';

const { Column } = Table;

const ClientTaskTable = ({tasks, userRole}) => {

    return (
        <Table 
            dataSource={tasks} 
            pagination={{position: ['topRight'], defaultCurrent: 1, defaultPageSize: 10, total: tasks.length}} 
            size='small' 
            scroll={{x: true}} 
            locale={{filterReset: 'Очистить', filterConfirm: 'Применить', emptyText: 'Заявки отсутствуют'}}>
            {userRole === 'client' &&
                <Column
                    title="" 
                    dataIndex="client_notifications" 
                    key="client_notifications" 
                    render={client_notifications => <Badge count={client_notifications} />}
                />
            }
            {userRole === 'specialist' &&
                <Column
                    title="" 
                    dataIndex="specialist_notifications" 
                    key="specialist_notifications" 
                    render={specialist_notifications => <Badge count={specialist_notifications} />}
                />
            }
            <Column 
                title="№" 
                dataIndex="id" 
                key="id"
                render={id => <Link to={`/cabinet/tasks/${id}`}>Заявка №{id}</Link>}
            />
            <Column 
                title="Статус" 
                dataIndex={['status', 'name']}
                key="status"
            />
            <Column 
                title="Дата создания" 
                dataIndex="created_time" 
                key="created_time" 
                render={created_time => <Time value={created_time} format='DD.MM.YYYY hh:mm'/>}
            />
            <Column 
                title="Дата изменения" 
                dataIndex="updated_time" 
                key="updated_time" 
                render={updated_time => <Time value={updated_time} format='DD.MM.YYYY hh:mm'/>}
            />
        </Table>
    )
}

export default ClientTaskTable;