import React, { useEffect } from 'react';

import { Col, Row, } from 'antd';
import { Typography } from 'antd';

import { getAdminTasks, selectHistoryTasks, selectLoading, selectTasks } from '../../../../../store/reducers/taskSlice';
import { useDispatch, useSelector } from 'react-redux';
import AdminTaskTable from './AdminTaskTable';
import DataSkeleton from '../../../../../components/general/DataSkeleton';

const { Title } = Typography;

const AdminTaskListPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdminTasks());
    }, []);    

    const tasks = useSelector(selectTasks);
    const historyTasks = useSelector(selectHistoryTasks);
    const isLoading = useSelector(selectLoading);

    return (
        <Row gutter={16}>
            <Col span={24} style={{textAlign: 'center'}}>
                {isLoading ?
                    <DataSkeleton/>
                    : 
                    <>
                        <Title level={2}>Текущие заявки</Title>
                        <AdminTaskTable tasks={tasks}/>

                        <div style={{marginTop: '50px'}}>
                            <Title level={2}>История заявок</Title>
                            <AdminTaskTable tasks={historyTasks}/>
                        </div>
                        
                    </>
                }
            </Col>
        </Row>
    )
}

export default AdminTaskListPage;