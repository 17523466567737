import React from 'react';

import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { ParsePhoneWhatsApp } from '../../../../components/general/FormControls/FormsControls';

const { Column } = Table;

const userRoleFilters = [
    {text: 'Администратор', value: 'admin'},
    {text: 'Менеджер', value: 'manager'},
    {text: 'Пользователь', value: 'client'},
    {text: 'Специалист', value: 'specialist'},
]

const UserTable = ({users}) => {

    return (
        <Table 
            dataSource={users} 
            pagination={{position: ['topRight'], defaultCurrent: 1, defaultPageSize: 10, total: users.length}} 
            size='small' 
            scroll={{x: true}} 
            locale={{filterReset: 'Очистить', filterConfirm: 'Применить', emptyText: 'Пользователи отсутствуют'}}
        >
            <Column
                title="id" 
                dataIndex="id" 
                key="id"                          
            />
            <Column
                title="ФИО" 
                dataIndex="last_name" 
                key="last_name"
                render={(last_name, record) => <Link to={`/cabinet/users/${record.id}`}>{last_name} {record.first_name} {record.patronymic}</Link>}                 
            />
            <Column
                title="Роль" 
                dataIndex="role_display" 
                key="role_display"
                filters={userRoleFilters}
                onFilter={(value, record) => record.role.indexOf(value) === 0}
            />
            <Column
                title="Номер телефона" 
                dataIndex="phone_number" 
                key="phone_number"
                render={phone_number => <ParsePhoneWhatsApp phone={phone_number}/>}                
            />
            <Column
                title="Email" 
                dataIndex="email" 
                key="email"
            />
            <Column
                title='Статус'
                dataIndex='is_active'
                key='is_active'
                render={is_active => is_active ? 'Активирован' : 'Отключен'}
            />
            <Column
                title="Компания" 
                dataIndex={['company', 'name']}
                key="name"
                render={(name, record) => !!name ? <Link to={`/cabinet/companies/${record.company.id}`}>{name}</Link> : 'Отсутствует'}
            />
        </Table>
    )
}

export default UserTable;