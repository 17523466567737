import { instance } from "./api";

export const itServiceAPI = {
    createTask(data) {
      return instance.post('it_service/tasks/', data);
    },
    getTasks() {
      return instance.get('it_service/tasks/');
    },
    getTaskDetail(id) {
      return instance.get(`it_service/tasks/${id}/`);
    },
    updateTaskStatus(task_id, status) {
      return instance.post(`it_service/update-task-status/${task_id}/`, {status});
    },
    getTasksByPhoneOrEmail(data) {
      return instance.post('it_service/get-tasks-by-phone-or-email/', data);
    }
}