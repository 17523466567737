import React, { useEffect } from 'react';

import { Col, Descriptions, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import Time from 'react-time-format';

import { Link, useHistory } from 'react-router-dom';
import { CompanyEditReduxForm } from './CompanyEditReduxForm';
import { getCompany, getUser, selectCompany, selectLoading } from '../../../../store/reducers/adminSlice';
import DataSkeleton from '../../../../components/general/DataSkeleton';

const { Title } = Typography;

const CompanyEditPage = (props) => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const id = props.match.params.companyId;

    const company = useSelector(selectCompany);
    const isLoading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getCompany(id));
    }, []);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col xs={24} md={22} lg={18} xl={14} xxl={10}>
                {isLoading ?
                    <DataSkeleton/>
                    :
                    <>
                        {!!company ?
                            <>
                                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Компания №{company.id}</Title>
                                <Descriptions title='Информация о компании' bordered column={1} size='small' style={{marginBottom: '20px'}}>
                                    <Descriptions.Item label='Дата создания'><Time value={company.created_time} format='DD.MM.YYYY hh:mm'/></Descriptions.Item>
                                    <Descriptions.Item label='Дата изменения'><Time value={company.updated_time} format='DD.MM.YYYY hh:mm'/></Descriptions.Item>
                                </Descriptions>
                                <CompanyEditReduxForm 
                                    isLoading={isLoading} 
                                    company={company}
                                    initialValues={{...company}} 
                                />
                            </>
                            :
                            <>
                                <Title level={3}>У вас нет доступа к данной странице</Title>
                            </>
                        }
                    </>
                }
                
            </Col>
        </Row>
    )
}

export default CompanyEditPage;