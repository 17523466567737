import React, { useEffect } from 'react';

import { Col, Descriptions, Row, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Time from 'react-time-format';
import { LeftOutlined } from '@ant-design/icons';

import { getTaskDetail, selectLoading, selectTask, updateTaskStatus } from '../../../../store/reducers/itCalcSlice';
import { Link, useHistory } from 'react-router-dom';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import ItCalcClientInfoTable from './ItCalcClientInfoTable';
import ItCalcTaskDetailTable from './ItCalcTaskDetailTable';
import { Option } from 'antd/lib/mentions';

const { Title } = Typography;

const ItCalcTaskDetailPage = (props) => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const id = props.match.params.taskId;

    const task = useSelector(selectTask);
    const isLoading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getTaskDetail(id));
    }, []);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    const handleStatusChange = (task_id, status) => {
        dispatch(updateTaskStatus({task_id, status}));
    }

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    :
                    <>
                        {!!task ?
                            <>
                                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Заявка №{task.id}</Title>
                                <Title level={5} style={{fontWeight: '700'}}>Статус: </Title>
                                <Descriptions bordered column={1} size='small' style={{marginBottom: '20px'}}>
                                    <Descriptions.Item label='Статус'>
                                        <Select 
                                            defaultValue={!!task.status && task.status.name} 
                                            style={{width: '300px'}} 
                                            onChange={(value) => handleStatusChange(task.id, value)}
                                            notFoundContent=''
                                        >
                                            {!!task.status && 
                                                <>
                                                    {task.status.children.map(status => <Option value={status.id}>{status.name}</Option>)}
                                                </>
                                            }                            
                                        </Select>
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Дата создания'><Time value={task.created_time} format='DD.MM.YYYY hh:mm'/></Descriptions.Item>
                                    <Descriptions.Item label='Дата обновления'><Time value={task.updated_time} format='DD.MM.YYYY hh:mm'/></Descriptions.Item>                                    
                                </Descriptions>
                                
                                <ItCalcClientInfoTable task={task}/>
                                <ItCalcTaskDetailTable task={task}/>
                            </>
                            :
                            <>
                                <Title level={3}>У вас нет доступа к данной заявке</Title>
                            </>
                        }
                    </>
                }
                
            </Col>
        </Row>
    )
}

export default ItCalcTaskDetailPage;