import React, { useEffect, useState } from 'react';

import { Table } from 'antd';
import { Link } from 'react-router-dom';
import Time from 'react-time-format';
import { ParsePhoneWhatsApp } from '../../../../components/general/FormControls/FormsControls';

const { Column } = Table;

const ServiceTaskTable = ({tasks, statuses}) => {

    const [statusFilters, setStatusFilters] = useState([]);

    useEffect(() => {
        setStatusFilters(statuses.map(status => ({text: status.name, value: status.name})));
    }, [])

    return (
        <Table 
            dataSource={tasks} 
            pagination={{position: ['topRight'], defaultCurrent: 1, defaultPageSize: 10, total: tasks.length}} 
            size='small' 
            scroll={{x: true}}
            locale={{filterReset: 'Очистить', filterConfirm: 'Применить', emptyText: 'Заявки отсутствуют'}} 
        >
            <Column
                title="№" 
                dataIndex="id" 
                key="id"
                render={id => <Link to={`/cabinet/service-tasks/${id}`}>Заявка №{id}</Link>}
            />
            <Column
                title="ФИО" 
                dataIndex="client_name" 
                key="client_name"
            />
            <Column
                title="Номер телефона" 
                dataIndex="phone_number" 
                key="phone_number"
                render={phone_number => <ParsePhoneWhatsApp phone={phone_number}/>}
            />
            <Column
                title="Email" 
                dataIndex="email" 
                key="email"
            />
            <Column
                title="Тип" 
                dataIndex="type" 
                key="type"
            />
            <Column
                title="Статус" 
                dataIndex={['status', 'name']} 
                key="status"
                filters={statusFilters}
                onFilter={(value, record) => record.status.name === value}
            />
            <Column 
                title="Дата создания" 
                dataIndex="created_time" 
                key="created_time" 
                render={created_time => <Time value={created_time} format='DD.MM.YYYY hh:mm'/>}
            />
            <Column
                title="Дата изменения" 
                dataIndex="updated_time" 
                key="updated_time" 
                render={updated_time => <Time value={updated_time} format='DD.MM.YYYY hh:mm'/>}
            />
        </Table>
    )
}

export default ServiceTaskTable;