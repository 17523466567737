import React from 'react';

import { PhoneOutlined } from "@ant-design/icons";

const CompanyPhones = () => {
    return (
        <div className='company_phones'>
            <PhoneOutlined style={{color: '#175fa4'}}/> +7 (775) 007-87-20<br/>
            <PhoneOutlined style={{color: '#175fa4'}}/> +7 (702) 605-15-21<br/>
            <a 
                href='https://wa.me/message/VYL6QAWFVFSDL1'
                target='_blank'
                rel='noreferrer'
            >
                Написать в WhatsApp
            </a>
        </div>
    )
}

export default CompanyPhones;