import { Modal } from 'antd';
import React from 'react';
import YandexMapFrame from './YandexMapFrame';

const YandexMapModal = ({isVisible, onClose}) => {
    return (
        <Modal width={1000} visible={isVisible} footer={null} onCancel={onClose}>
            <YandexMapFrame/>
        </Modal>
    )
}

export default YandexMapModal;