import React from "react";
import { Route, Redirect, withRouter, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoggedIn } from "../store/reducers/authSlice";

const PrivateRoute  = (props) => {
  const location = useLocation();
  const { component: Component, ...rest } = props;
  const isLoggedIn = useSelector(selectLoggedIn);

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoggedIn)
          return <Component {...props} />;
        else
          return <Redirect to={`/login/?redirect=${location.pathname}`} />
      }}
    />
  );
}

export default withRouter(PrivateRoute);