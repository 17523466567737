
import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import { selectLoading } from '../../store/reducers/taskSlice';
import { FastTaskReduxForm } from './FastTaskReduxForm';
import Title from 'antd/lib/typography/Title';
import { selectUser } from '../../store/reducers/authSlice';

const FastTaskCreate = () => {

    const user = useSelector(selectUser);
    const isLoading = useSelector(selectLoading);

    return (
        <Row>
            <Col span={24}>
                <Title level={3} style={{textAlign: 'center'}}>
                    Быстрая заявка
                </Title>
            </Col>
            <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}} xxl={{span: 8, offset: 8}}>
                <FastTaskReduxForm 
                    isLoading={isLoading} 
                    initialValues={{
                        client_name: !!user ? `${user.last_name} ${user.first_name}` : '',
                        phone_number: user?.phone_number,
                        email: user?.email
                    }}
                />
            </Col>
        </Row>
        
    )
}

export default FastTaskCreate;