import React, { useEffect } from 'react';

import { Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';

import { Link, useHistory } from 'react-router-dom';
import { UserCreateReduxForm } from './UserCreateReduxForm';
import { getCompanies, selectCompanies, selectLoading } from '../../../../store/reducers/adminSlice';

const { Title } = Typography;

const UserCreatePage = () => {
    
    const history = useHistory();
    const dispatch = useDispatch();

    const isLoading = useSelector(selectLoading);
    const companies = useSelector(selectCompanies);

    const BackButtonClickHandle = () => {
        history.goBack();
    }
    
    useEffect(() => {
        dispatch(getCompanies());
    }, []);

    return (
        <Row gutter={16}>
            <Col xs={24} md={22} lg={18} xl={14} xxl={10}>
                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Создание пользователя</Title>
                <UserCreateReduxForm 
                    isLoading={isLoading}
                    companies={companies}
                />
            </Col>
        </Row>
    )
}

export default UserCreatePage;