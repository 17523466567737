export const required = value => {
    if (value) return undefined;
    return "Обязательное поле";
}

export const requiredNumber = value => {
    if (value || value === 0) return undefined;
    return "Обязательное поле";
}

export const requiredArray = value => {
    if (!!value && value.length > 0) return undefined;
    return "Обязательное поле";
}

export const maxLengthCreator = maxLength => value => {
    if (value.length > maxLength) return `Max length is ${maxLength} symbols`;
    return undefined;
}

export const phoneMinLength = value => value && value.replace('_', '').length < 17 ? `Неправильный формат номера (8 (XXX) XXX-XX-XX)` : undefined

export const passwordMinLength = value => value && value.length < 8 ? 'Длина пароля не должна быть меньше 8 символов' : undefined;

export const passwordOnlyNumbers = value => {
    const numbers = /^[-+]?[0-9]+$/;
    if (value.match(numbers))
        return 'В пароле не могут содержаться только цифры';
    else
        return undefined;
}

export const EmailValidation = value => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!(!!value))
        return undefined;
    if (!re.test(value))
        return 'Введите корректный email';
    else
        return undefined;
}

export const OnlyLetters = value => {
    const re = /^([а-яё\s]+|[a-z\s]+)$/iu;
    if (value === '')
        return undefined; 
    if (!re.test(value))
        return 'Разрешен ввод только латинских, либо кириллических букв';
    else
        return undefined;
}