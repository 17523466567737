import React, { useEffect } from 'react';

import { Col, Row, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';

import { changeTaskStatus, getTaskDetail, selectLoading, selectTask } from '../../../../../store/reducers/taskSlice';
import { Link, useHistory } from 'react-router-dom';
import { TaskDetailReduxForm } from '../../../../../components/TaskDetail/TaskDetailReduxForm';
import DataSkeleton from '../../../../../components/general/DataSkeleton';
import ClientTaskDetailTable from './ClientTaskDetailTable';
import TaskDetailTimeline from '../../../../../components/TaskDetail/TaskDetailTimeline';
import ClientInfoTable from '../../../../../components/TaskDetail/ClientInfoTable';
import { selectUserRole } from '../../../../../store/reducers/authSlice';

const { Title, Text } = Typography;
const { Option } = Select;

const TaskDetailPage = (props) => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const id = props.match.params.taskId;

    const task = useSelector(selectTask);
    const isLoading = useSelector(selectLoading);
    const userRole = useSelector(selectUserRole);

    useEffect(() => {
        dispatch(getTaskDetail(id));
    }, []);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    const handleStatusChange = (task_id, status) => {
        dispatch(changeTaskStatus(task_id, status));
    }

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    :
                    <>
                        {!!task ?
                            <>
                                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Заявка №{task.id}</Title>
                                <Text style={{fontWeight: '700'}}>Статус: </Text>
                                {userRole === 'specialist' ?
                                    <Select 
                                        defaultValue={!!task.status && task.status.name} 
                                        style={{width: '300px'}} 
                                        onChange={(value) => handleStatusChange(task.id, value)}
                                        notFoundContent=''
                                    >
                                        {!!task.status && 
                                            <>
                                                {task.status.children.map(status => <Option value={status.id}>{status.name}</Option>)}
                                            </>
                                        }
                                    </Select>
                                    :
                                    <Text style={{fontWeight: '700'}}>{task.status?.name}</Text>
                                }                                
                                <ClientInfoTable client={task.client}/>
                                <ClientTaskDetailTable task={task} />
                                <TaskDetailTimeline task={task} />
                                <TaskDetailReduxForm isLoading={isLoading} taskId={task.id}/>
                            </>
                            :
                            <>
                                <Title level={3}>У вас нет доступа к данной заявке</Title>
                            </>
                        }
                    </>
                }
                
            </Col>
        </Row>
    )
}

export default TaskDetailPage;