import React, { useState } from 'react';
import moment from 'moment';

import { Button, Form } from 'antd';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';

import { useDispatch } from 'react-redux';
import { createField, Input, Label,  PhoneInput, CompanyTypeSelectInput, CompanyRegistrationTypeSelectInput, TextArea } from '../../../../components/general/FormControls/FormsControls';
import { reduxForm } from 'redux-form';
import { EmailValidation, OnlyLetters, phoneMinLength, required } from '../../../../utils/validators/validators';
import { createCompany } from '../../../../store/reducers/adminSlice';

const form = 'company_create';

const CompanyCreateForm = ({handleSubmit, isLoading}) => {

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(createCompany(formData));        
    }

    return (
        <Form layout='vertical'>
            {createField('Rs6.kz', "name", [required], Input, {label: Label('Название компании')})}
            {createField('Тип регистрации', 'registration_type', [required], CompanyRegistrationTypeSelectInput, {label: Label('Тип регистрации')})}
            {createField('', "type", [], CompanyTypeSelectInput, {label: 'Форма предпринимательства'})}
            {createField('БИН', "bin", [], Input, {label: 'БИН'})}
            {createField('Юридический адрес', "law_address", [], Input, {label: 'Юридический адрес'})}
            {createField('Расчетный счет', "bank_account", [], Input, {label: 'Расчетный счет'})}
            {createField('БИК', "bic", [], Input, {label: 'БИК'})}
            {createField('Свидетельство НДС', 'nds_certificate', [], Input, {label: 'Свидетельство НДС'})}
            {createField('Генеральный директор', "director", [OnlyLetters], Input, {label: 'Генеральный директор'})}
            {createField('Адрес', "address", [], Input, {label: 'Адрес'})}
            {createField('8 (7XX) XXX-XX-XX', "phone_number", [phoneMinLength], PhoneInput, {prefix: <PhoneOutlined />, label: 'Контактный номер телефона'})}
            {createField('info@rs6.kz', "email", [EmailValidation], Input, {prefix: <MailOutlined />, type: 'email', label: 'Электронный адрес компании'})}
            {createField('Rs6.kz', "requisites", [], TextArea, {label: 'Реквизиты компании'})}
            <Form.Item>
                <Button 
                    type="primary" 
                    loading={isLoading}
                    style={{marginTop: '20px'}}
                    onClick={handleSubmit(onSubmit)}>
                        Сохранить
                </Button>
            </Form.Item>
        </Form>
    )
}

export const CompanyCreateReduxForm = reduxForm({form: form})(CompanyCreateForm);