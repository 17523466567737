import React from 'react';

import { Button, Form } from 'antd';

import { useDispatch } from 'react-redux';
import { createField, Input, Label, PhoneInput, itServiceTaskTypeSelectInput, SmallTextArea } from '../../../../components/general/FormControls/FormsControls';
import { EmailValidation, phoneMinLength, required } from '../../../../utils/validators/validators';
import { reduxForm } from 'redux-form';
import { createTask } from '../../../../store/reducers/itServiceSlice';

const form = 'service_task_create';

const ServiceTaskCreateForm = ({handleSubmit, isLoading, specialists}) => {

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(createTask(formData));
    }

    return (
        <Form layout='vertical'>
            {createField('ФИО клиента', 'client_name', [required], Input, {label: Label('ФИО')})}
            {createField('8 (XXX) XXX-XX-XX', 'phone_number', [required, phoneMinLength], PhoneInput, {label: Label('Номер телефона')})}
            {createField('Email', 'email', [required, EmailValidation], Input, {label: Label('Email')})}
            {createField('Тип заявки', 'type', [required], itServiceTaskTypeSelectInput, {label: Label('Тип заявки')})}
            {createField('Название продукта', 'product_name', [required], Input, {label: Label('Название продукта')})}
            {createField('Артикул продукта', 'product_sku', [required], Input, {label: Label('Артикул продукта')})}
            {createField('Комментарий', 'commentary', [required], SmallTextArea, {label: Label('Комментарий')})}
            <Form.Item>
                <Button 
                    type="primary" 
                    loading={isLoading}
                    style={{marginTop: '20px'}}
                    onClick={handleSubmit(onSubmit)}>
                        Сохранить
                </Button>
            </Form.Item>
        </Form>
    )
}

export const ServiceTaskCreateReduxForm = reduxForm({form: form})(ServiceTaskCreateForm);