import React, { useEffect } from 'react';

import { Col, Descriptions, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';

import { getServiceDiscount, selectLoading, selectService, selectServiceDiscount } from '../../../../store/reducers/itCalcSlice';
import { Link, useHistory } from 'react-router-dom';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import { ItCalcServiceDiscountEditReduxForm } from './ItCalcServiceDiscountEditReduxForm';

const { Title } = Typography;

const ItCalcServiceDiscountEditPage = (props) => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const id = props.match.params.discountId;

    const serviceDiscount = useSelector(selectServiceDiscount);
    const service = useSelector(selectService);
    const isLoading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getServiceDiscount(id));
    }, []);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col xs={24} md={22} lg={18} xl={14} xxl={10}>
                {isLoading ?
                    <DataSkeleton/>
                    :
                    <>
                        {!!serviceDiscount ?
                            <>
                                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Скидка услуги №{serviceDiscount.id}</Title>
                                <Descriptions title="Информация об услуге" bordered column={1} size='small' style={{marginBottom: '20px'}}>
                                    <Descriptions.Item label="Услуга">{service.label}</Descriptions.Item>
                                    <Descriptions.Item label="Стоимость за единицу">{service.unit_price}</Descriptions.Item>
                                </Descriptions>
                                <ItCalcServiceDiscountEditReduxForm 
                                    isLoading={isLoading} 
                                    serviceDiscount={serviceDiscount}
                                    initialValues={{...serviceDiscount}} 
                                />
                            </>
                            :
                            <>
                                <Title level={3}>У вас нет доступа к данной странице</Title>
                            </>
                        }
                    </>
                }
                
            </Col>
        </Row>
    )
}

export default ItCalcServiceDiscountEditPage;