import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register, selectLoading } from '../../store/reducers/authSlice';
import { reduxForm } from 'redux-form';

import { Form, Button, Row, Col, Divider, Radio } from 'antd';
import { LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';

import { createField, Input, PhoneInput, Label, TextArea, CompanyTypeSelectInput, PasswordInput } from '../../components/general/FormControls/FormsControls.jsx';
import { passwordMinLength, passwordOnlyNumbers, phoneMinLength, required, EmailValidation, OnlyLetters } from '../../utils/validators/validators';
import { setMessage } from '../../store/reducers/messageSlice';
import { Link } from 'react-router-dom';

const form = 'registration';

const personPanes = [
    {title: 'Физическое лицо', key: '1'},
    {title: 'Юридическое лицо', key: '2'}
]

const companyPanes = [
    {title: 'Быстрая регистрация', key: 'fast'},
    {title: 'Полная регистрация', key: 'full'}
]

const UserFields = () => {
    return (
        <>
            {createField('info@rs6.kz', "email", [required, EmailValidation], Input, {prefix: <MailOutlined />, type: 'email', label: Label('Электронный адрес')})}
            {createField('Фамилия', "last_name", [required, OnlyLetters], Input, {prefix: <UserOutlined />, label: Label('Фамилия')})}
            {createField('Имя', "first_name", [required, OnlyLetters], Input, {prefix: <UserOutlined />, label: Label('Имя')})}
            {createField('Отчество', "patronymic", [OnlyLetters], Input, {prefix: <UserOutlined />, label: 'Отчество'})}
            {createField('8 (XXX) XXX-XX-XX', 'phone_number', [phoneMinLength, required], PhoneInput, {prefix: <PhoneOutlined />, label: Label('Номер телефона')})}
            {createField('Пароль', "password", [required, passwordMinLength, passwordOnlyNumbers], PasswordInput, {prefix: <LockOutlined />, label: Label('Пароль')})}
            {createField('Повторите пароль', "confirm_password", [required, passwordMinLength, passwordOnlyNumbers], PasswordInput, {prefix: <LockOutlined />, label: Label('Повторите пароль')})}
        </>
    )
}

const CompanyFields = () => {
    return (
        <>
            {createField('Rs6.kz', "c_name", [required], Input, {label: Label('Название компании')})}
            {createField('', "c_type", [required], CompanyTypeSelectInput, {label: Label('Форма предпринимательства')})}
            {createField('БИН', "c_bin", [required], Input, {label: Label('БИН')})}
            {createField('Юридический адрес', "c_law_address", [required], Input, {label: Label('Юридический адрес')})}
            {createField('Расчетный счет', "c_bank_account", [required], Input, {label: Label('Расчетный счет')})}
            {createField('БИК', "c_bic", [required], Input, {label: Label('БИК')})}
            {createField('Свидетельство НДС', 'c_nds_certificate', [required], Input, {label: 'Свидетельство НДС'})}
            {createField('Генеральный директор', "c_director", [required, OnlyLetters], Input, {label: Label('Генеральный директор')})}
            {createField('Адрес', "c_address", [], Input, {label: 'Адрес'})}
            {createField('+7 (7XX) XXX-XX-XX', "c_phone_number", [phoneMinLength, required], PhoneInput, {prefix: <PhoneOutlined />, label: Label('Контактный номер телефона')})}
            {createField('info@rs6.kz', "c_email", [required, EmailValidation], Input, {prefix: <MailOutlined />, type: 'email', label: Label('Электронный адрес компании')})}
        </>
    )
}

const FastCompanyFields = () => {
    return (
        <>
            {createField('Rs6.kz', "c_name", [required], Input, {label: Label('Название компании')})}
            {createField('Rs6.kz', "c_requisites", [required], TextArea, {label: Label('Реквизиты компании')})}
        </>
    )
}

const RegistrationForm = ({handleSubmit, isLoading}) => {

    const [personKey, setPersonKey] = useState(personPanes[0].key);
    const [companyKey, setCompanyKey] = useState(companyPanes[0].key);

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        if (formData.password === formData.confirm_password) {
            dispatch(register({
                ...formData,
                username: formData.email,
                type: personKey,
                company_type: companyKey,
            }));
        }
        else {
            dispatch(setMessage({message: ['Не совпадают введенные пароли'], type: 'error'}));
        }
    }

    return (        
        <Form layout='vertical'>
            <Row>
                <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}} xxl={{span: 8, offset: 8}} style={{textAlign: 'center'}}>
                    <Radio.Group defaultValue={personKey} buttonStyle="solid" size='small' onChange={(e) => setPersonKey(e.target.value)}>
                        {personPanes.map(pane => <Radio.Button value={pane.key}>{pane.title}</Radio.Button>)}
                    </Radio.Group>
                </Col>
            </Row>

            {personKey === '2' && 
                <Row style={{marginTop: '10px'}}>
                    <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}} xxl={{span: 8, offset: 8}} style={{textAlign: 'center'}}>
                        <Radio.Group defaultValue={companyKey} size='small' onChange={(e) => setCompanyKey(e.target.value)}>
                            {companyPanes.map(pane => <Radio value={pane.key}>{pane.title}</Radio>)}
                        </Radio.Group>
                    </Col>
                </Row>
            }
            
            <Row gutter={[16, 16]} style={{marginTop: '20px'}}>

                {personKey === '1' &&
                    <Col xs={{span: 24}} md={{span: 18, offset: 3}} lg={{span: 12, offset: 6}} xxl={{span: 8, offset: 8}}>
                        <UserFields/>
                    </Col>
                }
                {personKey === '2' &&
                    <>
                        <Col xs={{span: 24}} md={{span: 12}}>
                            <UserFields/>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 0}}>
                            <Divider plain>Компания</Divider>
                        </Col>
                        <Col xs={{span: 24}} md={{span: 12}}>
                            {companyKey === 'fast' 
                                ? <FastCompanyFields/>
                                : <CompanyFields/>
                            }
                            
                        </Col>
                    </>
                }
                <Col xs={{span: 24}} md={{span: 12, offset: 6}} lg={{span: 8, offset: 8}} xxl={{span: 6, offset: 9}}>
                    <Form.Item>
                        <Button type="primary" loading={isLoading} 
                            onClick={handleSubmit(onSubmit)}
                            className="login_form__button">
                                Зарегистрироваться
                        </Button>
                    </Form.Item>
                </Col>
                
            </Row>
        </Form>
    )
}

const UserRegistrationReduxForm = reduxForm({form: form})(RegistrationForm);

const RegistrationPage = () => {
    const isLoading = useSelector(selectLoading);

    return (
        <>                    
        <UserRegistrationReduxForm
            isLoading={isLoading}
        />
        <div style={{textAlign: 'center'}}>
            Уже зарегистрированы? <Link to='/login'>Войти</Link>
        </div>
        </>
    )
}

export default RegistrationPage;