import React, { useEffect, useState } from 'react';

import { Button, Col, Form, message, Row } from 'antd';

import { useDispatch } from 'react-redux';
import { createField, Input,  } from '../../components/general/FormControls/FormsControls';
import { required, EmailValidation, phoneMinLength } from '../../utils/validators/validators';
import { reduxForm } from 'redux-form';
import { PhoneInput } from '../general/FormControls/FormsControls';
import ServiceInput from './ServiceInput';
import Title from 'antd/lib/typography/Title';
import ServiceLabel from './ServiceLabel';
import { createTask } from '../../store/reducers/itCalcSlice';
import Text from 'antd/lib/typography/Text';
import { setMessage } from '../../store/reducers/messageSlice';

const form = 'it_calc';

const ItCalculatorForm = ({handleSubmit, isLoading, services}) => {

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        if (costSum > 0) dispatch(createTask({...formData, services: state}));
        else dispatch(setMessage(['Выберите необходимые услуги'], 'error'));
    }

    const [state, setState] = useState([]);
    const [costSum, setCostSum] = useState(0);
    const [discountedCostSum, setDiscountedCostSum] = useState(0);

    useEffect(() => {
        const arr = services.map(service => {
            return {...service, amount: service.default_amount, discount: 0}
        })
        setState(arr);
    }, []);

    useEffect(() => {
        let sum = 0;
        let discountedSum = 0;
        state.forEach(service => {
            sum += service.amount * service.unit_price;
            discountedSum += service.amount * (service.unit_price * (1 - service.discount * 0.01));
        })
        setCostSum(sum);
        setDiscountedCostSum(discountedSum);
    }, [state])

    const onChange = index => value => {
        let newArray = [...state];
        newArray[index].amount = value;
        newArray[index].discount = 0;
        if (newArray[index].discounts.length > 0) {
            newArray[index].discounts.forEach(dis => {
                if (newArray[index].amount >= dis.amount) {
                    newArray[index].discount = dis.discount;
                }
            })
        }
        setState(newArray);
    }

    return (
        <Form layout='horizontal' className='main_page__it_calc'>
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={3} style={{textAlign: 'center'}}>
                        Рассчитайте стоимость абонентского обслуживания для вашей организации
                    </Title>
                </Col>
                <Col xs={{span: 24}} lg={{span: 12, push: 12}} className='calc_price'>
                    <Title level={4}>Что включает в себя обслуживание?</Title>
                    <ServiceLabel
                        popover='Закрепление перечня ИТ-услуг и сроков реагирования на уровне договора'
                        content='SLA (Service Level Agreement)'
                    />
                    <ServiceLabel
                        popover='Решение задач на уровне пользователя'
                        content='1-я линия техподдержки'
                    />
                    <ServiceLabel
                        popover='Решение задач на уровне ИТ-инфраструктуры'
                        content='2-я линия техподдержки'
                    />
                    <ServiceLabel
                        popover='Обеспечение физического присутствия на объектах'
                        content='Выезды и физическое воздействие'
                    />
                </Col>
                <Col xs={{span: 24}} lg={{span: 12, pull: 12}} className='calc_services'>
                    <Title level={4}>Калькулятор IT услуг</Title>
                    {state.map((service, index) => {
                        return (
                            <ServiceInput service={service} onChange={onChange(index)}/>
                        )
                    })}
                </Col>                
            </Row>
            <Row gutter={16} className='calc_price'>
                <Col xs={{span: 24}} sm={{span: 18, offset: 3}} md={{span: 12, offset: 6}} lg={{span: 8, offset: 8}} xxl={{span: 6, offset: 9}}>
                    <Title level={4} style={{textAlign: 'center'}}>Оценочная стоимость обслуживания в месяц*</Title>
                    {costSum === discountedCostSum ?
                        <Title level={3} style={{textAlign: 'center'}}><Text style={{color: '#175fa4'}}>{costSum} тг</Text></Title>
                        :
                        <Title level={3} style={{textAlign: 'center'}}>
                            <Text delete style={{color: 'red'}}>{costSum}</Text> <Text style={{color: '#175fa4'}}>{discountedCostSum} тг</Text> 
                        </Title>
                    }
                    <ServiceLabel
                        content='Цена с учетом НДС'
                    />
                    <ServiceLabel
                        content='Минимальная стоимость услуг'
                    />
                    <ServiceLabel
                        content='Торг уместен'
                    />
                    <Text type='secondary' style={{fontSize: '0.8em'}}>*Узнайте итоговую стоимость у нашего менеджера</Text>
                </Col>                
            </Row>
            <Row gutter={16} className='calc_contact_form'>                
                <Col xs={{span: 24}} md={{span: 12}}>
                    {createField('ФИО', 'client_name', [required], Input, {})}
                    {createField('8 (XXX) XXX-XX-XX', 'phone_number', [required, phoneMinLength], PhoneInput, {})}
                    {createField('Email', 'email', [required, EmailValidation], Input, {})}
                </Col>
                <Col xs={{span: 24}} md={{span: 12}} style={{textAlign: 'center', margin: 'auto 0'}}>
                    <Button type="primary" loading={isLoading} onClick={handleSubmit(onSubmit)}>
                            Получить коммерческое предложение на почту
                    </Button>
                </Col>
            </Row>
            
        </Form>
    )
}

export const ItCalculatorReduxForm = reduxForm({form: form})(ItCalculatorForm);