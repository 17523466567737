import { Checkbox, Col, Form, InputNumber, Row, Slider, Switch } from 'antd';
import React, { useState } from 'react'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const ServiceInput = ({service, onChange}) => {

    if (service.input_type === 'slider') {
        return (
            <Row>
                <Col xs={{span: 24}} xxl={{span: 8}}>
                    {service.label}
                </Col>
                <Col xs={{span: 20}} xxl={{span: 13}}>
                    <Slider
                        trackStyle={{backgroundColor: '#175fa4'}}
                        handleStyle={{borderColor: '#175fa4'}}
                        min={service.min_amount}
                        max={service.max_amount}
                        value={service.amount}
                        onChange={onChange}
                    />
                </Col>
                <Col xs={{span: 4}} xxl={{span: 3}}>
                    <InputNumber
                        min={service.min_amount}
                        max={service.max_amount}
                        value={service.amount}
                        size='small'
                        style={{width: '60px', marginLeft: '10px'}}
                        onChange={value => typeof value === 'number' ? onChange(value) : onChange(0)}
                    />
                </Col>
                    
                                           
            </Row>
        )
    }
    else {
        return (
            <Row>                
                <Col span={24}>
                    <span style={{margin: '0 5px 0 0'}}>{service.label}</span>
                    <Switch 
                        style={{backgroundColor: '#175fa4'}} 
                        checked={service.amount > 0 ? true : false} 
                        onChange={checked => checked? onChange(1) : onChange(0)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Col>
            </Row>            
                            
        )
    }    
}

export default ServiceInput;