import React from 'react';

import { useDispatch } from 'react-redux';
import { createTask } from '../../../../store/reducers/taskSlice';


import { requiredArray } from '../../../../utils/validators/validators';
import { Button, Form } from 'antd';
import { reduxForm } from 'redux-form';
import { createField, Label, ServicesSelectCreate, TextArea } from '../../../../components/general/FormControls/FormsControls';

const form = 'task_create';

const TaskForm = ({handleSubmit, isLoading, services}) => {
    
    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(createTask(formData));
    }

    return (
        <Form layout='vertical'>
            {createField('Выберите одну или несколько услуг', 'services', [requiredArray], ServicesSelectCreate, {services, loading: isLoading, label: Label('Услуги')})}
            {createField('Оставьте сообщение, если у вас есть какие-либо пожелания', "commentary", [], TextArea, {label: 'Сообщение'})}
            <Form.Item>
                <Button type="primary" loading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    className="login_form__button">
                        Создать заявку
                </Button>
            </Form.Item>
        </Form>
    )
}

export const TaskCreateReduxForm = reduxForm({form: form})(TaskForm);