import React, { useState } from 'react';

import { Button, Form, message } from 'antd';

import { useDispatch } from 'react-redux';
import { createField, Label, NumberInput } from '../../../../components/general/FormControls/FormsControls';
import { requiredNumber } from '../../../../utils/validators/validators';
import { reduxForm } from 'redux-form';
import { createServiceDiscount } from '../../../../store/reducers/itCalcSlice';
import { PlusOutlined } from '@ant-design/icons';

const form = 'it_calc_service_discount_create';

const ItCalcServiceDiscountCreateForm = ({handleSubmit, isLoading, serviceId}) => {

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(createServiceDiscount({...formData, service: serviceId}));
    }

    return (
        <>
        <Form layout='inline' style={{marginTop: '20px'}}>
            {createField('', 'discount', [requiredNumber], NumberInput, {label: Label('Скидка (%)')})}
            {createField('', 'amount', [requiredNumber], NumberInput, {label: Label('Минимальное количество')})}
        </Form>
            <Button 
                type="primary" 
                loading={isLoading}
                onClick={handleSubmit(onSubmit)}
                style={{marginTop: '10px'}}
            >
                <PlusOutlined/> Создать
            </Button>
        </>
    )
}

export const ItCalcServiceDiscountCreateReduxForm = reduxForm({form: form})(ItCalcServiceDiscountCreateForm);