import { Button, Form } from "antd";
import { useDispatch } from "react-redux";
import { reduxForm } from "redux-form";

import { createTaskMessage } from "../../store/reducers/taskSlice";
import { required } from "../../utils/validators/validators";
import { createField, SmallTextArea } from "../general/FormControls/FormsControls";

const form = 'task_detail';

const TaskDetailForm = ({handleSubmit, isLoading, taskId}) => {
    
    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(createTaskMessage(taskId, formData.commentary));
    }

    return (        
        <Form layout='vertical'>
            {createField('Оставьте сообщение', "commentary", [required], SmallTextArea)}
            <Form.Item>
                <Button type="primary" loading={isLoading}
                    onClick={handleSubmit(onSubmit)}>
                        Отправить сообщение
                </Button>
            </Form.Item>
        </Form>        
    )
}

export const TaskDetailReduxForm = reduxForm({form: form})(TaskDetailForm);