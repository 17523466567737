import React from 'react';

import { Col, Row } from 'antd';
import ItCalculator from '../../components/ItCalculator/ItCalculator';
import FastTaskCreate from '../../components/FastTaskCreate/FastTaskCreate';

const MainPage = () => {    

    return (
        <Row gutter={16}>
            <Col xs={{span: 24}}>
                <FastTaskCreate/>
                <ItCalculator/>
            </Col>
        </Row>
    )
}

export default MainPage;