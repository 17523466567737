import React, { useEffect } from 'react';

import { Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';


import { Link, useHistory } from 'react-router-dom';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import { selectLoading, selectTask } from '../../../../store/reducers/taskSlice';
import FastTaskInfoTable from './FastTaskInfoTable';
import { getFastTaskDetail } from '../../../../store/reducers/taskSlice';

const { Title } = Typography;

const FastTaskDetailPage = (props) => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const id = props.match.params.taskId;

    const task = useSelector(selectTask);
    
    
    const isLoading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getFastTaskDetail(id));
    }, []);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    :
                    <>
                        {!!task ?
                            <>
                                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Заявка №{task.id}</Title>                                
                                <FastTaskInfoTable task={task}/>                                
                            </>
                            :
                            <>
                                <Title level={3}>У вас нет доступа к данной заявке</Title>
                            </>
                        }
                    </>
                }                
            </Col>
        </Row>
    )
}

export default FastTaskDetailPage;