import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
    return (
        <div className='logo__container'>
            <Link to='/' className=''>
                <img src='/media/logo.png' alt='' className='logo__icon'/>
            </Link>
        </div>
    )
}

export default Logo;