import React, { useState } from 'react';

import { Button, Form } from 'antd';

import { useDispatch } from 'react-redux';
import { createField, Input, NumberInput, SmallTextArea, Label, SpecialistSelect } from '../../../../components/general/FormControls/FormsControls';
import { required } from '../../../../utils/validators/validators';
import { reduxForm } from 'redux-form';
import { updateService } from '../../../../store/reducers/taskSlice';

const form = 'service_edit';

const ServiceEditForm = ({handleSubmit, isLoading, service, specialists}) => {

    const specialistsSelected = () => {
        const specs = specialists.filter(specialist => specialist.services.includes(service.id));
        return specs.map(spec => spec.id);
    }
        
    const [serviceSpecialists, setSpecialists] = useState(specialistsSelected);

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        const serviceData = {
            name: formData.name, 
            description: 
            formData.description, 
            price: formData.price, 
            specialists: serviceSpecialists
        }
        dispatch(updateService(service.id, serviceData));
    }

    const onSpecialistChange = (value) => {
        setSpecialists(value);
    }

    return (
        <Form layout='vertical'>
            {createField('Название', 'name', [required], Input, {label: Label('Название')})}
            {createField('Описание', 'description', [required], SmallTextArea, {label: Label('Описание')})}
            {createField('', 'price', [required], NumberInput, {label: Label('Стоимость (тг)')})}
            <SpecialistSelect
                specialists={specialists} 
                label={Label('Специалисты')} 
                placeholder='Выберите одного или несколько специалистов'
                value={serviceSpecialists}
                onChange={onSpecialistChange}
            />
            <Form.Item>
                <Button type="primary" loading={isLoading}
                    onClick={handleSubmit(onSubmit)}>
                        Сохранить
                </Button>
            </Form.Item>
        </Form>
    )
}

export const ServiceEditReduxForm = reduxForm({form: form})(ServiceEditForm);