import React from 'react';
import { useDispatch } from 'react-redux';

import { Select, Table, Typography } from 'antd';
import Time from 'react-time-format';
import { changeServiceSpecialist } from '../../../../../store/reducers/taskSlice';

const { Column } = Table;
const { Option } = Select;
const { Title } = Typography;

const AdminTaskDetailTable = ({task}) => {

    const dispatch = useDispatch();

    const handleChange = (service_id, specialist_id) => {
        dispatch(changeServiceSpecialist(service_id, specialist_id));
    }

    return (
        <Table 
            dataSource={task.task_services} 
            pagination={false}
            expandable={{
                expandedRowRender: record => <p style={{ margin: 0 }}>{record.service.description}</p>,
                rowExpandable: record => record.service.name,
            }}
            rowKey='id'
            size='small'
            scroll={{x: true}}
            title={() => <Title level={5} style={{fontWeight: 700}}>Услуги</Title>}
        >
            <Column 
                title="Услуга"
                key="name"
                dataIndex={['service', 'name']}
            />
            <Column
                title="Стоимость услуги"
                dataIndex="price"
                key="price"
                render={price => <>{parseInt(price)} тг</>}
            />
            <Column 
                title="Специалист" 
                dataIndex={['service', 'service_specialists']}
                key="service_specialists"
                render={(service_specialists, record) => {
                    return (
                        <Select defaultValue={!!record.specialist && record.specialist.id} style={{width: '300px'}} onChange={(value) => handleChange(record.id, value)}>
                            {service_specialists.map((i) => {
                                return <Option value={i.id}>{i.user.email}:{i.user.last_name} {i.user.first_name}</Option>
                            })}
                        </Select>
                    )
                }}
            />
            <Column 
                title="Дата изменения" 
                dataIndex="updated_time" 
                key="updated_time"
                render={updated_time => <Time value={updated_time} format='DD.MM.YYYY hh:mm'/>}
            />
        </Table>
    )
}

export default AdminTaskDetailTable;