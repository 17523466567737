import { Button, Card, Col } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React from 'react';
import { Link } from 'react-router-dom';

const MenuItem = ({item}) => {
    return (
        <Col xs={24} sm={12} md={8} lg={6}>
            <Link to={item.link}>
                <Card hoverable>
                    <Meta title={<>{item.icon} {item.name}</>} />
                </Card>
            </Link>
        </Col>
    )
}

export default MenuItem;