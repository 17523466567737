import React from "react";
import { Route, Redirect, withRouter, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoggedIn, selectUserRole } from "../store/reducers/authSlice";

const PrivateClientSpecialistRoute  = (props) => {  
  const { component: Component, ...rest } = props;
  const isLoggedIn = useSelector(selectLoggedIn);  
  const isClientOrSpecialist = ['client', 'specialist'].includes(useSelector(selectUserRole));
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoggedIn && isClientOrSpecialist)
          return <Component {...props} />;
        else if (isLoggedIn)
          return <Redirect to='/' />
        else if (!isLoggedIn)
          return <Redirect to={`/login/?redirect=${location.pathname}`} />
      }}
    />
  );
}

export default withRouter(PrivateClientSpecialistRoute);