import React, { useEffect } from 'react';

import { Col, Row, Typography, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';


import { Link, useHistory } from 'react-router-dom';
import { TaskDetailReduxForm } from '../../../../../components/TaskDetail/TaskDetailReduxForm';
import DataSkeleton from '../../../../../components/general/DataSkeleton';
import AdminTaskDetailTable from './AdminTaskDetailTable';
import TaskDetailTimeline from '../../../../../components/TaskDetail/TaskDetailTimeline';
import ClientInfoTable from '../../../../../components/TaskDetail/ClientInfoTable';
import { changeTaskStatus, getAdminTaskDetail, selectLoading, selectTask } from '../../../../../store/reducers/taskSlice';

const { Title, Text } = Typography;
const { Option } = Select;

const AdminTaskDetailPage = (props) => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const id = props.match.params.taskId;

    const task = useSelector(selectTask);
    const isLoading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getAdminTaskDetail(id));
    }, []);

    const handleStatusChange = (task_id, status) => {
        dispatch(changeTaskStatus(task_id, status));
    }

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    :
                    <>
                        {!!task ?
                            <>
                                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Заявка №{task.id}</Title>
                                <Text style={{fontWeight: '700'}}>Статус: </Text>
                                <Select 
                                    defaultValue={!!task.status && task.status.name} 
                                    style={{width: '300px'}} 
                                    onChange={(value) => handleStatusChange(task.id, value)}
                                    notFoundContent=''
                                >
                                    {!!task.status && 
                                        <>
                                            {task.status.children.map(status => <Option value={status.id}>{status.name}</Option>)}
                                        </>
                                    }
                                </Select>
                                <ClientInfoTable client={task.client}/>
                                <AdminTaskDetailTable task={task} />
                                <TaskDetailTimeline task={task} />
                                <TaskDetailReduxForm isLoading={isLoading} taskId={task.id} />           
                            </>
                            :
                            <>
                                <Title level={3}>У вас нет доступа к данной заявке</Title>
                            </>
                        }
                    </>
                }                
            </Col>
        </Row>
    )
}

export default AdminTaskDetailPage;