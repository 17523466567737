import React from 'react';

import { Table } from 'antd';
import { Link } from 'react-router-dom';

const { Column } = Table;

const ServiceTable = ({services}) => {

    return (
        <Table 
            dataSource={services} 
            pagination={{position: ['topRight'], defaultCurrent: 1, defaultPageSize: 10, total: services.length}} 
            size='small' 
            scroll={{x: true}} 
            locale={{filterReset: 'Очистить', filterConfirm: 'Применить', emptyText: 'Услуги отсутствуют'}}
        >
            <Column
                title="Название" 
                dataIndex="name" 
                key="name"
                render={(name, record) => <Link to={`/cabinet/services/${record.id}`}>{name}</Link>}                 
            />
            <Column
                title="Описание" 
                dataIndex="description" 
                key="description"                 
            />
            <Column
                title="Стоимость" 
                dataIndex="price" 
                key="price"
                render={price => <>{parseInt(price)} тг</>}
            />
        </Table>
    )
}

export default ServiceTable;