import React from 'react';

import { Table } from 'antd';
import { Link } from 'react-router-dom';

import Time from 'react-time-format';

const { Column } = Table;

const ContractTable = ({contracts}) => {

    return (
        <Table 
            dataSource={contracts} 
            pagination={{position: ['topRight'], defaultCurrent: 1, defaultPageSize: 10, total: contracts.length}} 
            size='small' 
            scroll={{x: true}} 
            locale={{filterReset: 'Очистить', filterConfirm: 'Применить', emptyText: 'Договоры отсутствуют'}}
        >
            <Column
                title="№" 
                dataIndex="id" 
                key="id"
                render={(id) => <Link to={`/cabinet/contracts/${id}`}>Договор №{id}</Link>}
            />
            <Column
                title="Компания" 
                dataIndex="company" 
                key="company"
                render={(company) => <Link to={`/cabinet/companies/${company.id}`}>{company.type} {company.name}</Link>}
            />
            <Column
                title="Менеджер" 
                dataIndex="manager" 
                key="manager"
                render={manager => <Link to={`/cabinet/users/${manager.id}`}>{manager.email} : {manager.last_name} {manager.first_name}</Link>}
            />
            <Column 
                title="Дата начала" 
                dataIndex="start_date" 
                key="start_date" 
                render={start_date => <Time value={start_date} format='DD.MM.YYYY'/>}
            />
            <Column 
                title="Дата окончания" 
                dataIndex="expiration_date" 
                key="expiration_date" 
                render={expiration_date => <Time value={expiration_date} format='DD.MM.YYYY'/>}
            />
            <Column
                title="Просрочен?"
                dataIndex="is_expired"
                key="is_expired"
                render={is_expired => is_expired ? 'Да' : 'Нет'}
            />
        </Table>
    )
}

export default ContractTable;