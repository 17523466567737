import React, { useEffect } from 'react';
import { Footer as AntFooter } from 'antd/lib/layout/layout';
import { Row } from 'antd';
import FooterLogos from './FooterLogos';
import FooterSpecialists from './FooterSpecialists';
import FooterCompanyInfo from './FooterCompanyInfo';
import FooterContacts from './FooterContacts';
import FooterMap from './FooterAddress';
import { useDispatch } from 'react-redux';
import { getArticles } from '../../store/reducers/articleSlice';

const Footer = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getArticles());
    }, [])

    return (
        <AntFooter className='main_footer'>
            <Row gutter={16}>
                <FooterLogos/>
                <FooterSpecialists/>
                <FooterCompanyInfo/>
                <FooterContacts/>
                <FooterMap/>
            </Row>
        </AntFooter>
    )
}

export default Footer;