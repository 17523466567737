import { createSlice } from '@reduxjs/toolkit';
import { itCalcAPI } from '../../api/itCalcAPI';
import { errorHandle } from './errorHandler';
import { setMessage } from './messageSlice';
import { reset } from 'redux-form';

export const itCalcSlice = createSlice({
    name: 'itCalc',
    initialState: {
        tasks: [],
        historyTasks: [],
        task: {},
        services: [],
        service: {},
        serviceDiscount: {},
        isLoading: false,
        isCreationLoading: false,
    },
    reducers: {
        setTasks: (state, action) => {
            state.tasks = action.payload;
        },
        setHistoryTasks: (state, action) => {
            state.historyTasks = action.payload;
        },
        setTask: (state, action) => {
            state.task = action.payload;
        },
        setServices: (state, action) => {
            state.services = action.payload;
        },
        setService: (state, action) => {
            state.service = action.payload;
        },
        setServiceDiscount: (state, action) => {
            state.serviceDiscount = action.payload;
        },  
        setSpecialists: (state, action) => {
            state.specialists = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setCreationLoading: (state, action) => {
            state.isCreationLoading = action.payload;
        }
    }
});

export const { setTasks, setHistoryTasks, setTask, setServices, setService, setServiceDiscount, setLoading, setCreationLoading } = itCalcSlice.actions;


export const getServices = () => dispatch => {
    dispatch(setLoading(true));
    itCalcAPI.getServices()
    .then(response => {
        if (response.status === 200) {            
            dispatch(setServices(response.data.services))
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const getService = (id) => dispatch => {
    dispatch(setLoading(true));
    itCalcAPI.getService(id)
    .then(response => {
        if (response.status === 200) {            
            dispatch(setService(response.data.service))
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const updateService = (id, data) => dispatch => {
    dispatch(setLoading(true));
    itCalcAPI.updateService(id, data)
    .then(response => {
        if (response.status === 200) {
            dispatch(setMessage({message: ['Услуга успешно сохранена'], type: 'success'}));            
            dispatch(setService(response.data.service));
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const getServiceDiscount = id => dispatch => {
    dispatch(setLoading(true));
    itCalcAPI.getServiceDiscount(id)
    .then(response => {
        if (response.status === 200) {
            dispatch(setServiceDiscount(response.data.service_discount));
            dispatch(setService(response.data.service));
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const updateServiceDiscount = (id, data) => dispatch => {
    dispatch(setLoading(true));
    itCalcAPI.updateServiceDiscount(id, data)
    .then(response => {
        if (response.status === 200) {
            dispatch(setMessage({message: ['Скидка успешно сохранена'], type: 'success'}));
            dispatch(setServiceDiscount(response.data.service_discount));
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const createServiceDiscount = (data) => dispatch => {
    dispatch(setLoading(true));
    itCalcAPI.createServiceDiscount(data)
    .then(response => {
        if (response.status === 201) {
            dispatch(setMessage({message: ['Скидка успешно создана'], type: 'success'}));
            dispatch(setService(response.data.service));
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const deleteServiceDiscount = (id) => dispatch => {
    dispatch(setLoading(true));
    itCalcAPI.deleteServiceDiscount(id)
    .then(response => {
        if (response.status === 200) {
            dispatch(setMessage({message: ['Скидка успешно удалена'], type: 'success'}));
            dispatch(setService(response.data.service));
            dispatch(setLoading(false));
        }
    })
    .catch(error => {
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const createTask = (data) => dispatch => {
    dispatch(setCreationLoading(true));
    itCalcAPI.createTask(data)
    .then((response) => {
        if (response.status === 201) {            
            dispatch(setMessage({message: ['Заявка успешно создана, ожидайте коммерческое предложение на почте'], type: 'success'}));
            dispatch(reset('it_calc'));
            dispatch(setCreationLoading(false));
        }
    })
    .catch((error) => {        
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const getTasks = () => dispatch => {
    dispatch(setLoading(true));
    itCalcAPI.getTasks()
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTasks(response.data.tasks));
            dispatch(setHistoryTasks(response.data.history_tasks));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {        
        errorHandle(error, dispatch);
        dispatch(setLoading(false));
    })
}

export const getTaskDetail = id => dispatch => {
    dispatch(setLoading(true));
    itCalcAPI.getTaskDetail(id)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTask(response.data.task));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setTask({}));
        dispatch(setLoading(false));
    })
}

export const updateTaskStatus = (data) => dispatch => {
    dispatch(setLoading(true));
    itCalcAPI.updateTaskStatus(data)
    .then((response) => {
        if (response.status === 200) {
            dispatch(setTask(response.data.task));
            dispatch(setMessage({message: ['Статус заявки успешно изменен'], type: 'success'}));
            dispatch(setLoading(false));
        }
    })
    .catch((error) => {
        errorHandle(error, dispatch);
        dispatch(setTask({}));
        dispatch(setLoading(false));
    })
}

export const selectLoading = state => state.itCalc.isLoading;
export const selectCreationLoading = state => state.itCalc.isCreationLoading;
export const selectServices = state => state.itCalc.services;
export const selectService = state => state.itCalc.service;
export const selectServiceDiscount = state => state.itCalc.serviceDiscount;
export const selectTasks = state => state.itCalc.tasks;
export const selectHistoryTasks = state => state.itCalc.historyTasks;
export const selectTask = state => state.itCalc.task;

export default itCalcSlice.reducer;