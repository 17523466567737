import React from 'react';

import { Descriptions, Select } from 'antd';
import Time from 'react-time-format';
import { ParsePhoneWhatsApp } from '../../../../components/general/FormControls/FormsControls';
import { selectUserRole } from '../../../../store/reducers/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Text from 'antd/lib/typography/Text';
import { changeFastTaskSpecialist, changeFastTaskStatus, selectSpecialists } from '../../../../store/reducers/taskSlice';

const { Option } = Select;

const FastTaskInfoTable = ({task}) => {

    const dispatch = useDispatch();

    const specialists = useSelector(selectSpecialists);
    const userRole = useSelector(selectUserRole);

    const handleStatusChange = (taskId, status) => {
        dispatch(changeFastTaskStatus(taskId, status));
    }

    const handleSpecialistChange = (taskId, specialist_id) => {
        dispatch(changeFastTaskSpecialist(taskId, specialist_id));
    }

    if (!!task) {
        return (            
            <Descriptions bordered column={1} size='small' style={{marginBottom: '20px'}}>
                <Descriptions.Item label="ФИО">{task.client_name}</Descriptions.Item>
                <Descriptions.Item label="Email">{task.email}</Descriptions.Item>
                <Descriptions.Item label="Номер телефона"><ParsePhoneWhatsApp phone={task.phone_number}/></Descriptions.Item>
                <Descriptions.Item label="Комментарий">{task.commentary}</Descriptions.Item>
                <Descriptions.Item label="Статус">
                    {['manager', 'admin', 'specialist'].includes(userRole) ? 
                        <Select 
                            defaultValue={!!task.status && task.status.name} 
                            style={{width: '300px'}} 
                            onChange={(value) => handleStatusChange(task.id, value)}
                            notFoundContent=''
                        >
                            {!!task.status && 
                                <>
                                    {task.status.children.map(status => <Option value={status.id}>{status.name}</Option>)}
                                </>
                            }                            
                        </Select>
                        :
                        <Text>{!!task.status && task.status.name}</Text>
                    }
                </Descriptions.Item>
                <Descriptions.Item label="Специалист">
                    {['manager', 'admin'].includes(userRole) ? 
                        <Select defaultValue={!!task.specialist && task.specialist.id} style={{width: '300px'}} onChange={(value) => handleSpecialistChange(task.id, value)}>
                            {specialists.map((i) => {
                                return <Option value={i.id}>{i.user.email}:{i.user.last_name} {i.user.first_name}</Option>
                            })}
                        </Select>
                        :
                        <>
                            {!!task.specialist ?
                                <Text>{task.specialist.user.email}:{task.specialist.user.last_name} {task.specialist.user.first_name}</Text>
                                :
                                <Text>Не назначен</Text>
                            }
                        </>
                    }
                </Descriptions.Item>
                <Descriptions.Item label='Дата создания'><Time value={task.created_time} format='DD.MM.YYYY hh:mm'/></Descriptions.Item>
                <Descriptions.Item label='Дата обновления'><Time value={task.updated_time} format='DD.MM.YYYY hh:mm'/></Descriptions.Item>
            </Descriptions>
        )
    }
    else return null;    
}

export default FastTaskInfoTable;