import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { PlusOutlined } from '@ant-design/icons';

import DataSkeleton from '../../../../components/general/DataSkeleton';
import { getUsers, selectLoading, selectUsers } from '../../../../store/reducers/adminSlice';
import UserTable from './UserTable';

const UserListPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUsers());
    }, []);

    const isLoading = useSelector(selectLoading);
    const users = useSelector(selectUsers);

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    : 
                    <>
                        <Title level={2} style={{textAlign: 'center'}}>Пользователи</Title>
                        <UserTable users={users}/>
                        <Button type='primary' style={{marginTop: '10px'}}>
                            <Link to='/cabinet/create-user'><PlusOutlined /> Создать пользователя</Link>
                        </Button>
                    </>
                    
                }
            </Col>
        </Row>
    )
}

export default UserListPage;