import React, { useEffect } from 'react';
import { Router as MyRouter, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Layout, message } from 'antd';

import { getUserData, refreshToken, selectInitialized, selectRefresh } from '../store/reducers/authSlice';
import { selectMessage, selectType } from '../store/reducers/messageSlice';

import LoginRoute from './LoginRoute';
import UnPrivateRoute from './UnPrivateRoute';
import PrivateRoute from './PrivateRoute';
import PrivateClientSpecialistRoute from './PrivateClientSpecialistRoute';
import PrivateAdminRoute from './PrivateAdminRoute';
import PrivateClientRoute from './PrivateClientRoute';
import PrivateManagerRoute from './PrivateManagerRoute';

import Header from '../components/Header/Header';
import Sider from '../components/CabinetSider/CabinetSider';
import history from './history';

import CabinetPage from '../pages/Cabinet/CabinetPage/CabinetPage';
import MainPage from '../pages/MainPage/MainPage';
import RegistrationPage from '../pages/RegistrationPage/RegistrationPage';

import TaskCreatePage from '../pages/Cabinet/Tasks/TaskCreate/TaskCreatePage';
import ClientTaskListPage from '../pages/Cabinet/Tasks/TaskList/Client/ClientTaskListPage';

import AdminTaskListPage from '../pages/Cabinet/Tasks/TaskList/Admin/AdminTaskListPage';
import AdminTaskDetailPage from '../pages/Cabinet/Tasks/TaskDetail/Admin/AdminTaskDetailPage';

import TaskDetailPage from '../pages/Cabinet/Tasks/TaskDetail/Client/TaskDetailPage';


import ServiceListPage from '../pages/AdminCabinet/Services/ServiceList/ServiceListPage';
import ServiceEditPage from '../pages/AdminCabinet/Services/ServiceEdit/ServiceEditPage';
import ServiceCreatePage from '../pages/AdminCabinet/Services/ServiceCreate/ServiceCreatePage';

import ItCalcTaskListPage from '../pages/Cabinet/ItCalcTasks/ItCalcTaskList/ItCalcTaskListPage';
import ItCalcTaskDetailPage from '../pages/Cabinet/ItCalcTasks/ItCalcTaskDetail/ItCalcTaskDetailPage';

import ServiceTaskCreatePage from '../pages/Cabinet/ServiceTasks/ServiceTaskCreate/ServiceTaskCreatePage';
import ServiceTaskListPage from '../pages/Cabinet/ServiceTasks/ServiceTaskList/ServiceTaskListPage';
import ServiceTaskDetailPage from '../pages/Cabinet/ServiceTasks/ServiceTaskDetail/ServiceTaskDetailPage';
import ServiceTaskPage from '../pages/ServiceTask/ServiceTaskPage';

import UserListPage from '../pages/AdminCabinet/Users/UserList/UserListPage';
import UserEditPage from '../pages/AdminCabinet/Users/UserEdit/UserEditPage';
import UserCreatePage from '../pages/AdminCabinet/Users/UserCreate/UserCreatePage';

import SpecialistListPage from '../pages/AdminCabinet/Specialists/SpecialistList/SpecialistListPage';
import SpecialistEditPage from '../pages/AdminCabinet/Specialists/SpecialistEdit/SpecialistEditPage';
import SpecialistCreatePage from '../pages/AdminCabinet/Specialists/SpecialistCreate/SpecialistCreatePage';

import CompanyListPage from '../pages/AdminCabinet/Companies/CompanyList/CompanyListPage';
import CompanyEditPage from '../pages/AdminCabinet/Companies/CompanyEdit/CompanyEditPage';
import CompanyCreatePage from '../pages/AdminCabinet/Companies/CompanyCreate/CompanyCreatePage';

import ItCalcServiceListPage from '../pages/AdminCabinet/ItCalcServices/ItCalcServiceList/ItCalcServiceListPage';
import ItCalcServiceEditPage from '../pages/AdminCabinet/ItCalcServices/ItCalcServiceEdit/ItCalcServiceEditPage';

import ItCalcServiceDiscountEditPage from '../pages/AdminCabinet/ItCalcServices/ItCalcServiceDiscountEdit/ItCalcServiceDiscountEditPage';
import UserInfoPage from '../pages/Cabinet/UserInfo/UserInfoPage';
import CompanyInfoPage from '../pages/Cabinet/UserInfo/CompanyInfoPage';
import FastTaskListPage from '../pages/Cabinet/FastTasks/FastTaskList/FastTaskListPage';
import FastTaskDetailPage from '../pages/Cabinet/FastTasks/FastTaskDetail/FastTaskDetailPage';
import Footer from '../components/Footer/Footer';
import ContractListPage from '../pages/AdminCabinet/Contracts/ContractList/ContractListPage';
import OutsorcingPage from '../pages/AdminCabinet/OutsorcingPage/OutsorcingPage';
import ArticleCreatePage from '../pages/AdminCabinet/Articles/ArticleCreate/ArticleCreatePage';
import ArticleListPage from '../pages/AdminCabinet/Articles/ArticleList/ArticleListPage';
import ArticleDetailPage from '../pages/AdminCabinet/Articles/ArticleDetail/ArticleDetailPage';
import ScrollToTop from '../components/ScrollToTop';
import ArticleEditPage from '../pages/AdminCabinet/Articles/ArticleEdit/ArticleEditPage';
import ConfirmationPage from '../pages/RegistrationPage/ConfirmationPage';
import PasswordForgetPage from '../pages/PasswordForgetPage/PasswordForgetPage';

const { Content } = Layout;

const Router = () => {

    const messageArray = useSelector(selectMessage);
    const messageType = useSelector(selectType);
    const isInitialized = useSelector(selectInitialized);
    const refresh = useSelector(selectRefresh);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserData());
    }, []);

    useEffect(() => {
        if (!!refresh) {
            dispatch(refreshToken(refresh));
        }
    }, [refresh]);

    useEffect(() => {
        if (!!messageArray) {
            let messageStr = '';
            messageStr = messageStr.concat(messageArray);
            if (messageType === 'error') {
                message.error(messageStr, 5);
            }
            else if (messageType === 'success') {
                message.success(messageStr, 5);
            }
        }
    }, [messageArray]);

    return (
        <>
            {isInitialized &&
                <MyRouter history={history}>
                    <Layout className='main_layout'>
                        <Header/>
                        <ScrollToTop/>
                        <Switch>
                            <Layout className='content_layout'>
                                <Sider/>
                                <Content className="main_content">
                                    <Route exact path='/' component={MainPage}/>
                                    <LoginRoute/>
                                    <UnPrivateRoute exact path='/registration' component={RegistrationPage}/>
                                    <UnPrivateRoute exact path='/registration/confirmation/' component={ConfirmationPage}/>
                                    <UnPrivateRoute exact path='/password-forget' component={PasswordForgetPage}/>

                                    <Route exact path='/it-service' component={ServiceTaskPage}/>

                                    <PrivateRoute exact path='/cabinet' component={CabinetPage}/>
                                    
                                    <PrivateClientRoute exact path='/cabinet/create-task' component={TaskCreatePage}/>
                                    <PrivateAdminRoute exact path='/cabinet/create-service' component={ServiceCreatePage}/>
                                    <PrivateAdminRoute exact path='/cabinet/create-user' component={UserCreatePage}/>
                                    <PrivateAdminRoute exact path='/cabinet/create-specialist' component={SpecialistCreatePage}/>
                                    <PrivateAdminRoute exact path='/cabinet/create-company' component={CompanyCreatePage}/>
                                    <PrivateAdminRoute exact path='/cabinet/create-article' component={ArticleCreatePage}/>
                                    <PrivateManagerRoute exact path='/cabinet/create-it-service-task' component={ServiceTaskCreatePage}/>
                                    
                                    <PrivateClientSpecialistRoute exact path='/cabinet/tasks' component={ClientTaskListPage}/>
                                    <PrivateClientSpecialistRoute exact path='/cabinet/tasks/:taskId' component={TaskDetailPage}/>

                                    <PrivateManagerRoute exact path='/cabinet/admin-tasks' component={AdminTaskListPage}/>
                                    <PrivateManagerRoute exact path='/cabinet/admin-tasks/:taskId' component={AdminTaskDetailPage}/>

                                    <PrivateRoute exact path='/cabinet/fast-tasks' component={FastTaskListPage}/>
                                    <PrivateRoute exact path='/cabinet/fast-tasks/:taskId' component={FastTaskDetailPage}/>
                                    
                                    <PrivateAdminRoute exact path='/cabinet/services' component={ServiceListPage}/>
                                    <PrivateAdminRoute exact path='/cabinet/services/:serviceId' component={ServiceEditPage}/>

                                    <PrivateAdminRoute exact path='/cabinet/users' component={UserListPage}/>
                                    <PrivateAdminRoute exact path='/cabinet/users/:userId' component={UserEditPage}/>

                                    <PrivateAdminRoute exact path='/cabinet/specialists' component={SpecialistListPage}/>
                                    <PrivateAdminRoute exact path='/cabinet/specialists/:specId' component={SpecialistEditPage}/>

                                    <PrivateAdminRoute exact path='/cabinet/companies' component={CompanyListPage}/>
                                    <PrivateAdminRoute exact path='/cabinet/companies/:companyId' component={CompanyEditPage}/>

                                    <PrivateAdminRoute exact path='/cabinet/it-calc-services' component={ItCalcServiceListPage}/>
                                    <PrivateAdminRoute exact path='/cabinet/it-calc-services/:serviceId' component={ItCalcServiceEditPage}/>

                                    <PrivateAdminRoute exact path='/cabinet/it-calc-service-discounts/:discountId' component={ItCalcServiceDiscountEditPage}/>

                                    <PrivateAdminRoute exact path='/cabinet/outsorcing' component={OutsorcingPage}/>
                                    <PrivateAdminRoute exact path='/cabinet/outsorcing/contracts' component={ContractListPage}/>

                                    <PrivateAdminRoute exact path='/cabinet/articles' component={ArticleListPage}/>
                                    <PrivateAdminRoute exact path='/cabinet/articles/:articleId' component={ArticleEditPage}/>
                                    <Route exact path='/articles/:articleId' component={ArticleDetailPage}/>


                                    <PrivateManagerRoute exact path='/cabinet/it-calc-tasks' component={ItCalcTaskListPage}/>
                                    <PrivateManagerRoute exact path='/cabinet/it-calc-tasks/:taskId' component={ItCalcTaskDetailPage}/>
                                    
                                    <PrivateManagerRoute exact path='/cabinet/service-tasks' component={ServiceTaskListPage}/>
                                    <PrivateManagerRoute exact path='/cabinet/service-tasks/:taskId' component={ServiceTaskDetailPage}/>
                                    
                                    <PrivateRoute exact path='/cabinet/user-info-page' component={UserInfoPage}/>
                                    <PrivateRoute exact path='/cabinet/company-info-page' component={CompanyInfoPage}/>
                                </Content>
                            </Layout>                            
                        </Switch>
                        <Footer/>
                    </Layout>
                </MyRouter>
            }
        </>
    )
}

export default Router;