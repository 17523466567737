import React from "react";
import { Route, Redirect, withRouter, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoggedIn, selectUserRole } from "../store/reducers/authSlice";

const PrivateAdminRoute = (props) => {
  const { component: Component, ...rest } = props;
  const isLoggedIn = useSelector(selectLoggedIn);
  const isAdmin = useSelector(selectUserRole) === 'admin';
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoggedIn && isAdmin)
          return <Component {...props} />;
        else if (isLoggedIn && !isAdmin)
          return <Redirect to='/' />;
        else if (!isLoggedIn)
          return <Redirect to={`/login/?redirect=${location.pathname}`} />
      }}
    />
  );
}

export default withRouter(PrivateAdminRoute);