import React from "react";
import { Route, Redirect, withRouter, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoggedIn } from "../store/reducers/authSlice";
import LoginPage from "../pages/LoginPage/LoginPage";

const LoginRoute  = () => {
  const isLoggedIn = useSelector(selectLoggedIn);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirect = params.get('redirect');

  return (
    <Route exact path='/login/'
      render={props => {
        if (isLoggedIn) {
          if (!!redirect) 
            return <Redirect to={redirect} />          
          else
            return <Redirect to='/' />;         
        }
        else 
          return <LoginPage {...props} redirect={redirect}/>
      }}
    />
  )
  
}

export default withRouter(LoginRoute);