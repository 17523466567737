import React from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'antd';
import Time from 'react-time-format';
import { ParsePhoneWhatsApp } from '../../../../components/general/FormControls/FormsControls';

const { Column } = Table;

const FastTaskTable = ({tasks, userRole}) => {

    return (
        <Table 
            dataSource={tasks} 
            pagination={{position: ['topRight'], defaultCurrent: 1, defaultPageSize: 10, total: tasks.length}} 
            size='small' 
            scroll={{x: true}} 
            locale={{filterReset: 'Очистить', filterConfirm: 'Применить', emptyText: 'Заявки отсутствуют'}}
        >
            <Column 
                title="№" 
                dataIndex="id" 
                key="id"
                render={id => <Link to={`/cabinet/fast-tasks/${id}`}>Заявка №{id}</Link>}
            />
            <Column title="ФИО клиента" dataIndex="client_name" key="client_name"/>
            <Column 
                title='Номер телефона' 
                dataIndex='phone_number' 
                key='phone_number'
                render={phone_number => <ParsePhoneWhatsApp phone={phone_number}/>}
            />
            <Column 
                title="Статус" 
                dataIndex={['status', 'name']}
                key="status"
            />
            <Column 
                title="Дата создания" 
                dataIndex="created_time" 
                key="created_time" 
                render={created_time => <Time value={created_time} format='DD.MM.YYYY hh:mm'/>}
            />
            <Column 
                title="Дата изменения" 
                dataIndex="updated_time" 
                key="updated_time" 
                render={updated_time => <Time value={updated_time} format='DD.MM.YYYY hh:mm'/>}
            />
        </Table>
    )
}

export default FastTaskTable;