import React, { useEffect } from 'react';

import { Button, Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined, PlusOutlined } from '@ant-design/icons';

import { getService, selectLoading, selectService } from '../../../../store/reducers/itCalcSlice';
import { Link, useHistory } from 'react-router-dom';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import { ItCalcServiceEditReduxForm } from './ItCalcServiceEditReduxForm';
import ItCalcServiceDiscountTable from '../ItCalcServiceDiscountList/ItCalcServiceDiscountTable';
import { ItCalcServiceDiscountCreateReduxForm } from '../ItCalcServiceDiscountCreate/ItCalcServiceDiscountCreateReduxForm';

const { Title } = Typography;

const ItCalcServiceEditPage = (props) => {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const id = props.match.params.serviceId;

    const service = useSelector(selectService);
    const isLoading = useSelector(selectLoading);

    useEffect(() => {
        dispatch(getService(id));
    }, []);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col xs={24} md={22} lg={18} xl={14} xxl={10}>
                {isLoading ?
                    <DataSkeleton/>
                    :
                    <>
                        {!!service ?
                            <>
                                <Title level={2}><Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Услуга №{service.id}</Title>
                                <ItCalcServiceEditReduxForm 
                                    isLoading={isLoading} 
                                    service={service}  
                                    initialValues={{...service}} 
                                />
                                <Title level={4}>Скидки</Title>
                                <ItCalcServiceDiscountTable discounts={service.discounts}/>
                                <ItCalcServiceDiscountCreateReduxForm isLoading={isLoading} serviceId={service.id}/>
                            </>
                            :
                            <>
                                <Title level={3}>У вас нет доступа к данной странице</Title>
                            </>
                        }
                    </>
                }
                
            </Col>
        </Row>
    )
}

export default ItCalcServiceEditPage;