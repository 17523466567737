import { Card, Col, Row, Switch } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/general/Loader';
import { selectLoading, selectTasks, setTasks } from '../../store/reducers/itServiceSlice';
import { ServiceTaskReduxForm } from './ServiceTaskReduxForm';

const ServiceTaskPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTasks([]));
    }, []);

    const tasks = useSelector(selectTasks);
    const isLoading = useSelector(selectLoading);

    return (
        <>
            <ServiceTaskReduxForm isLoading={isLoading}/>            
            {isLoading ?
                <Row gutter={16}>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <Loader/>
                    </Col>
                </Row>
            :
                <>
                    {tasks.length > 0 &&
                        <>
                            {tasks.map(task => {
                                return (
                                    <Row gutter={16} style={{marginTop: '20px'}}>
                                        <Col xs={24} md={{span: 12, offset: 6}} lg={{span: 8, offset: 8}} xl={{span: 6, offset: 9}}>
                                            <Card title={`Заявка №${task.id}`}>
                                                <p>Тип: {task.type}</p>
                                                <p>Товар: {task.product_name} ({task.product_sku})</p>
                                                <p>Статус: {task.status.name}</p>
                                            </Card>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </>
                    }
                </>
            }
        </>
    )
}

export default ServiceTaskPage;