import React, { useEffect } from 'react';

import { Button, Col, Row } from 'antd';
import { Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import { Link } from 'react-router-dom';
import ArticleTable from './ArticleTable';
import { getArticles, selectArticles, selectLoading } from '../../../../store/reducers/articleSlice';

const { Title } = Typography;

const ArticleListPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getArticles());
    }, []);

    const articles = useSelector(selectArticles);
    const isLoading = useSelector(selectLoading);

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    : 
                    <>
                        <Title level={2} style={{textAlign: 'center'}}>Статьи</Title>
                        <ArticleTable articles={articles}/>
                        <Button type='primary' style={{marginTop: '10px'}}>
                            <Link to='/cabinet/create-article'><PlusOutlined /> Создать статью</Link>
                        </Button>
                    </>                
                }
            </Col>
        </Row>
    )
}

export default ArticleListPage;