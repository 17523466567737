import React, { useState } from 'react';

import { Button, Form } from 'antd';

import { useDispatch } from 'react-redux';
import { createField, Input, NumberInput, Label, ItCalcServiceValueTypeSelect, ItCalcServiceInputTypeSelect } from '../../../../components/general/FormControls/FormsControls';
import { required, requiredNumber } from '../../../../utils/validators/validators';
import { reduxForm } from 'redux-form';
import { updateService } from '../../../../store/reducers/itCalcSlice';

const form = 'it_calc_service_edit';

const ItCalcServiceEditForm = ({handleSubmit, isLoading, service}) => {

    const dispatch = useDispatch();

    const onSubmit = (formData) => {
        dispatch(updateService(service.id, {
            label: formData.label,
            input_type: formData.input_type,
            value_type: formData.value_type,
            unit_price: formData.unit_price,
            default_amount: formData.default_amount,
            min_amount: formData.min_amount,
            max_amount: formData.max_amount
        }));
    }

    return (
        <Form layout='vertical'>            
            {createField('Название', 'label', [required], Input, {label: Label('Название')})}
            {createField('', 'unit_price', [required], NumberInput, {label: Label('Стоимость за единицу (тг)')})}
            {createField('', 'input_type', [required], ItCalcServiceInputTypeSelect, {label: Label('Тип переключателя')})}
            {createField('', 'value_type', [required], ItCalcServiceValueTypeSelect, {label: Label('Тип значения')})}
            {createField('', 'default_amount', [requiredNumber], NumberInput, {label: Label('Количество по умолчанию')})}
            {createField('', 'min_amount', [requiredNumber], NumberInput, {label: Label('Минимальное количество')})}
            {createField('', 'max_amount', [required], NumberInput, {label: Label('Максимальное количество')})}
            <Form.Item>
                <Button type="primary" loading={isLoading}
                    onClick={handleSubmit(onSubmit)}>
                        Сохранить
                </Button>
            </Form.Item>
        </Form>
    )
}

export const ItCalcServiceEditReduxForm = reduxForm({form: form})(ItCalcServiceEditForm);