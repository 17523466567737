import React, { useRef } from "react";
import { Field } from "redux-form";
import { 
    Input as AntInput, 
    Form, 
    InputNumber, 
    DatePicker as AntDatePicker,
    Upload,
    Button,
    Checkbox as AntCheckbox,
    Select,
    Checkbox
} from 'antd';

import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

import moment from 'moment';

import { EyeInvisibleOutlined, EyeTwoTone, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { MaskedInput } from "antd-mask-input";

const { Option } = Select;

const { Dragger } = Upload;

const FormControl = ({input, meta: {touched, error}, children, label = null}) => {
    const hasError = touched && error;

    if (hasError) {
        return (
            <Form.Item
                label={label}
                hasFeedback
                validateStatus="error"
                help={error}
                className='form__item'
            >
                {children}
            </Form.Item>
        )
    } 
    else {
        return (
            <Form.Item label={label} className='form__item'>
                {children}
            </Form.Item>
        )
    }
}

export const createField = (placeholder,  name, validators, component, props = {}, text = "") => (
    <div>
        <Field
            placeholder={placeholder}
            name={name}
            validate={validators}
            component={component}
            {...props}
        />
        {text}
    </div>
)

export const TextArea = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <AntInput.TextArea  rows={15} {...input} {...restProps} />
        </FormControl>
    )
}

export const SmallTextArea = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <AntInput.TextArea  rows={4} {...input} {...restProps} />
        </FormControl>
    )
}

export const Input = (props) => {    
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <AntInput size="large" {...input} {...restProps} on/>
        </FormControl>
    )    
}

export const PasswordInput = (props) => {
    
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <AntInput.Password size="large" {...input} {...restProps}  on
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
        </FormControl>
    )  
}

export const NumberInput = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <InputNumber
                min={0}
                max={9999999}
                {...input}
                {...restProps}
            />
        </FormControl>
        
    )
}

export const PercentInput = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <InputNumber
                min={0}
                max={100}
                {...input}
                {...restProps}
            />
        </FormControl>
        
    )
}

export const CheckboxInput = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <Checkbox {...input} {...restProps} checked={input.value}>
                {props.placeholder}
            </Checkbox>
        </FormControl>
    )
}

export const RoleSelectInput = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <Select {...input} {...restProps}
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
            >
                <Option value="admin">Администратор</Option>
                <Option value="manager">Менеджер</Option>
                <Option value="specialist">Специалист</Option>
                <Option value="client">Клиент</Option>
            </Select>
        </FormControl>
    )
}

export const TypeSelectInput = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <Select {...input} {...restProps}
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
            >
                <Option></Option>
                <Option value={1}>Физическое лицо</Option>
                <Option value={2}>Юридическое лицо</Option>
            </Select>
        </FormControl>
    )
}

export const CompanySelectInput = (props) => {
    const {input, meta, child, companies, ...restProps} = props;
    return (
        <FormControl {...props}>
            <Select {...input} {...restProps}
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
            >
                <Option></Option>
                {companies.map(company => <Option value={company.id}>{company.type} {company.name}</Option>)}
            </Select>
        </FormControl>
    )
}

export const CompanyTypeSelectInput = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <Select {...input} {...restProps}
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
            >
                <Option value="ТОО">Товарищество с ограниченной ответственностью</Option>
                <Option value="АО">Акционерное общество</Option>
                <Option value="ТДО">Товарищество с дополнительной ответственностью</Option>
                <Option value="КТ">Коммандитное товарищество</Option>
                <Option value="ПТ">Простое товарищество</Option>
            </Select>
        </FormControl>
    )
}

export const CompanyRegistrationTypeSelectInput = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <Select {...input} {...restProps}
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
            >
                <Option value="fast">Быстрая</Option>
                <Option value="full">Полная</Option>                
            </Select>
        </FormControl>
    )
}

export const itServiceTaskTypeSelectInput = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <Select {...input} {...restProps}
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
            >
                <Option value="repair">Ремонт</Option>
                <Option value="return">Возврат</Option>                
            </Select>
        </FormControl>
    )
}

export const SpecialistUserSelect = (props) => {
    const {input, meta, child, users, ...restProps} = props;
    return (
        <FormControl {...props}>
            <Select {...input} {...restProps}
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
                notFoundContent='Нет пользователей'
            >
                {users.map(user => <Option value={user.id}>{user.email} : {user.last_name} {user.first_name} {user.patronymic}</Option>)}
                                        
                
            </Select>
        </FormControl>
    )
}

export const SpecialistCreateServicesSelect = (props) => {
    const {input, meta, child, isLoading, ...restProps} = props;
    input.value = [...input.value];
    return (
        <FormControl {...props}>
            <Select {...input} {...restProps} loading={isLoading} mode='multiple'
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
            >
                {props.services.map((service) => {
                return (
                    <Option value={service.id}>
                        {service.name}
                    </Option>
                )
            })}
            </Select>
        </FormControl>
    )
}

export const ServicesSelectCreate = (props) => {
    const {input, meta, child, isLoading, ...restProps} = props;
    input.value = [...input.value];
    return (
        <FormControl {...props}>
            <Select {...input} {...restProps} loading={isLoading} mode='multiple'
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
            >
                {props.services.map((service) => {
                return (
                    <Option value={service.id}>
                        {service.name} {service.price > 0 && `(${parseInt(service.price)} тг)`}
                    </Option>
                )
            })}
            </Select>
        </FormControl>
    )
}

export const ServicesSelect = ({services, label, placeholder, value, onChange}) => {
    return (
        <Form.Item label={label} >
            <Select 
                mode='multiple' 
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            >
                {services.map((service) => {
                    return (
                        <Option value={service.id}>
                            {service.name}
                        </Option>
                    )
                })}
            </Select>
        </Form.Item>        
    )
}

export const SpecialistSelect = ({specialists, label, placeholder, value, onChange}) => {
    return (
        <Form.Item label={label} >
            <Select 
                mode='multiple' 
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            >
                {specialists.map((specialist) => {
                    return (
                        <Option value={specialist.id}>
                            {specialist.user.email}: {specialist.user.last_name} {specialist.user.first_name}
                        </Option>
                    )
                })}
            </Select>
        </Form.Item>        
    )
}

export const SpecialistSelectCreate = (props) => {
    const {input, meta, child, isLoading, specialists, ...restProps} = props;
    input.value = [...input.value];
    return (
        <FormControl {...props}>
            <Select {...input} {...restProps} loading={isLoading} mode='multiple'
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
            >
                {specialists.map((specialist) => {
                    return (
                        <Option value={specialist.id}>
                            {specialist.user.email}: {specialist.user.last_name} {specialist.user.first_name}
                        </Option>
                    )
                })}
            </Select>
            </FormControl>
        
    )
}

export const ItCalcServiceValueTypeSelect = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <Select {...input} {...restProps}
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
            >
                <Option value='unit'>единицы</Option>
                <Option value='hours'>часы</Option>
            </Select>
        </FormControl>
    )
}

export const ItCalcServiceInputTypeSelect = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <Select {...input} {...restProps}
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
            >
                <Option value='slider'>Слайдер</Option>
                <Option value='checkbox'>Чекбокс</Option>
            </Select>
        </FormControl>
    )
}

export const PhoneInput = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
    <FormControl {...props}>
        <MaskedInput 
            mask='8 (111) 111-11-11' 
            {...input}
            {...restProps}
        />
    </FormControl>
    )
}

export const DatePickerInput = (props) => {
    const {input, meta, child, ...restProps} = props;
    return (
    <FormControl {...props}>
        <MaskedInput 
            mask='11.11.1111'
            {...input}
            {...restProps}
        />
    </FormControl>
    )
}

export const DatePicker = ({label, placeholder, value, onChange}) => {
    const dateFormat = 'DD.MM.YYYY';    
    return (
        <Form.Item label={label}>
            <AntDatePicker 
                placeholder={placeholder} 
                format={dateFormat} 
                size="large" 
                defaultValue={!!value ? moment(value, 'YYYY-MM-DD') : null}
                onChange={onChange}
            />
        </Form.Item>
    )    
}

export const DatePickerCreate = (props) => {
    const dateFormat = 'DD.MM.YYYY';
    const {input, meta, child, ...restProps} = props;
    return (
        <FormControl {...props}>
            <AntDatePicker format={dateFormat} size="large" {...input} {...restProps} 
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
            />
        </FormControl>
    )    
}

export const DragAndDrop = (props) => {    
    return (
    <Dragger {...props}>
        <p className="ant-upload-drag-icon">
        <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
        band files
        </p>
    </Dragger> 
  );
}

export const ImageUpload = ({label="Загрузить изображение", onChange}) => {
    return (
        <Form.Item
            label={label}
            className='form__item'
        >
            <Upload
                beforeUpload={() => false}
                maxCount={1}
                onChange={onChange}
                accept='.jpeg, .jpg, .png'
            >
                <Button icon={<UploadOutlined />}>Изображение</Button>
            </Upload>
        </Form.Item>
            
        
        
    )
}

export const ImageUploadAsync = ({label="Загрузить изображение", onChange}) => {
    return (
        <Form.Item
            label={label}
            className='form__item'
        >
            <Upload
                beforeUpload={() => false}
                maxCount={1}
                onChange={onChange}
                showUploadList={false}
                accept='.jpeg, .jpg, .png'
            >
                <Button icon={<UploadOutlined />}>Загрузить изображение</Button>
            </Upload>
        </Form.Item>
    )
}

export const Label = (label) => {
    return (
        <span>
            {label}
            <span style={{color: 'red'}}> *</span>
        </span>
    )
}

export const ParsePhoneWhatsApp = ({phone}) => {
    if (!!phone) {
        const parsedPhone = phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replace('8', '7');
        return (
            <a href={`https://wa.me/${parsedPhone}`} target='_blank' rel="noreferrer">
                {phone}
            </a>
        )
    }
    else {
        return (<></>)
    }
    
}

export const RubricsSelect = (props) => {
    const {input, meta, child, isLoading, rubrics, ...restProps} = props;

    return (
        <FormControl {...props}>
            <Select {...input} {...restProps} loading={isLoading}
                onFocus={e => e.preventDefault()}
                onBlur={e => e.preventDefault()}
                notFoundContent='Нет рубрик'
            >
                {rubrics.map((rubric) => {
                    return (
                        <Option value={rubric.id}>
                            {rubric.name}
                        </Option>
                    )
                })}
            </Select>
        </FormControl>        
    )
}

export const RichTextEditor = ({label, value, setValue}) => {

    const toolbarContainer = useRef(null);

    return (
        <Form.Item
            label={label}
            className='form__item'
        >
            <div ref={toolbarContainer} id="toolbar-container"></div>
            <div className="editor-wrapper">
                <CKEditor
                    editor={DecoupledEditor}
                    data={value}
                    config={{ckfinder: {
                        uploadUrl: '/api/article/image/',
                        options: {
                            resourceType: "Images",
                        }
                    }
                    }}
                    onInit={ editor => {
                        toolbarContainer.current.appendChild( editor.ui.view.toolbar.element );
                    }}
                    onChange={(event, editor) => {
                        setValue(editor.getData());
                    }}
                />
            </div>
        </Form.Item>
    )
}

export default FormControl;