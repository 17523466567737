import { Col } from 'antd';
import React from 'react';
import Logo from '../general/Logo';
import LogoShop from '../general/LogoShop';

const FooterLogos = () => {
    return (
        <Col xs={24} md={4}>
            <Col xs={24} className='footer_block'>
                <Logo/>
            </Col>
            <Col xs={24} className='footer_block'>
                <LogoShop/>
            </Col>
        </Col>
    )
}

export default FooterLogos;