import React, { useEffect } from 'react';

import { Button, Col, Row } from 'antd';
import { Typography } from 'antd';
import { LeftOutlined, PlusOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import DataSkeleton from '../../../../components/general/DataSkeleton';
import { Link, useHistory } from 'react-router-dom';
import ContractTable from './ContractTable';
import { getContracts, selectContracts, selectLoading } from '../../../../store/reducers/outsorcingSlice';

const { Title } = Typography;

const ContractListPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getContracts());
    }, []);

    const contracts = useSelector(selectContracts);
    const isLoading = useSelector(selectLoading);

    const BackButtonClickHandle = () => {
        history.goBack();
    }

    return (
        <Row gutter={16}>
            <Col span={24}>
                {isLoading ?
                    <DataSkeleton/>
                    : 
                    <>
                        <Title level={2}>
                            <Link onClick={BackButtonClickHandle}><LeftOutlined/></Link> Договоры
                        </Title>
                        <ContractTable contracts={contracts}/>
                        <Button type='primary' style={{marginTop: '10px'}}>
                            <Link to='/cabinet/create-contract'><PlusOutlined /> Создать договор</Link>
                        </Button>
                    </>                
                }
            </Col>
        </Row>
    )
}

export default ContractListPage;