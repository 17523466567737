import { instance } from "./api"

export const articleAPI = {
    getRubrics() {
        return instance.get('article/rubrics/');
    },
    getArticles() {
        return instance.get('article/articles/');
    },
    getArticleDetail(id) {
        return instance.get(`article/articles/${id}/`);
    },
    deleteArticle(id) {
        return instance.delete(`article/articles/${id}/`);
    },
    getArticleEdit(id) {
        return instance.get(`article/articles/edit/${id}/`);
    },
    createArticle(data) {
        return instance.post('article/articles/', data);
    },
    updateArticle(id, data) {
        return instance.put(`article/articles/edit/${id}/`, data);
    }
}